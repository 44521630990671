import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import jobMetaDataOverview from './meta-data/job-meta-data/all-jobs-overview-meta-data';

// Importing components for SSC
import Ssccgl from './ssc/Ssc-cgl';
import SscChsl from './ssc/Ssc-chsl';
import SscMts from './ssc/Ssc-mts';
import SscGd from './ssc/Ssc-gd';
import SscStenographer from './ssc/Ssc-stenographer';

// Importing components for Defence Sector
import AirForceCommonAdmissionTest from './defence-sector/AirForceCommonAdmissionTest'; 
import CombinedDefenceServices from './defence-sector/CombinedDefenceServices'; 
import IndianCoastGuard from './defence-sector/IndianCoastGuard'; 
import IndianNavyEntranceTest from './defence-sector/IndianNavyEntranceTest'; 
import IndianNavySsr from './defence-sector/IndianNavySsr'; 
import IndianTerritorialArmyForce from './defence-sector/IndianTerritorialArmyForce'; 
import NavalAcademy from './defence-sector/NavalAcademy'; 
import Nda from './defence-sector/Nda'; 

// Importing components for Railways
import Rpf from './railways/Rpf';
import RrbNTPC from './railways/RrbNtpc';
import RrbAlpRecruitment from './railways/RrbAssistantLocoPilot';
import RrbGroupD from './railways/RrbGroupD';
import RailwayJuniorEngineer from './railways/RrbJE';
import RrbParamedical from './railways/RrbParamedicalStaff';

// Importing components for UPSC
import UpscExam from './upse/UpscExam';

// Importing components for Bihar
import BiharPoliceSI from './bihar-based/BiharPoliceSI';
import BpscAssistantTeacher from './bihar-based/BpscAssistantTeacher';
import BsphclRecruitment from './bihar-based/BsphclRecruitment';
import BtscHealthRecruitment from './bihar-based/BtscHealthRecruitment';
import Polytechnic from './bihar-based/Polytechnic';
import BiharDistrictCourtRecruitment from './bihar-based/BiharCivilCourt.jsx';

// Importing components for UP
import UPHealthTraining from './up-based/UPHealthTraining';
import UPParamedicalEntrance from './up-based/UPParamedicalEntrance';
import UPPoliceSIASI from './up-based/UPPoliceSIASI';
import UPTETExam from './up-based/UPTETExam';
import UpSssc from './up-based/UpSssc.jsx';
import UppscAgricultureServices from './up-based/Upagri.jsx';

// Importing components from Banking Sector
import PnbApprentice from './banking-sector/PnbApprentice';

// NTA
import CsirNet from './nta/CsirNetExam.jsx';

// Importing link definitions
import { sscJobLinks } from './ssc/SscLinks';
import { defenceJobLinks } from './defence-sector/DefenceLink';
import { railwaysJobLinks } from './railways/RailwaysLinks';
import { upscJobLinks } from './upse/UpscLinks';
import { biharJobLinks } from './bihar-based/BiharLinks';
import { upJobLinks } from './up-based/UpLinks';
import { pnbJobLinks } from './banking-sector/BankLinks';
import { ntaJobLinks} from './nta/NtaLink.js'


// Function to remove '/job' prefix from URLs
const removeJobPrefix = (url) => {
  return url && url.startsWith('/job') ? url.replace(/^\/job/, '') : url || '';
};


// Main Routes component
const JobRoutes = () => (
  <Routes>
    {/* SSC */}
    <Route 
      path={removeJobPrefix(sscJobLinks.sscCgl?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.sscCgl.title}</title>
            <meta name="description" content={jobMetaDataOverview.sscCgl.description} />
            <meta name="keywords" content={jobMetaDataOverview.sscCgl.keywords} />
          </Helmet>
          <Ssccgl />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(sscJobLinks.sscChsl?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.sscChsl.title}</title>
            <meta name="description" content={jobMetaDataOverview.sscChsl.description} />
            <meta name="keywords" content={jobMetaDataOverview.sscChsl.keywords} />
          </Helmet>
          <SscChsl />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(sscJobLinks.sscMts?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.sscMts.title}</title>
            <meta name="description" content={jobMetaDataOverview.sscMts.description} />
            <meta name="keywords" content={jobMetaDataOverview.sscMts.keywords} />
          </Helmet>
          <SscMts />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(sscJobLinks.sscGd?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.sscGd.title}</title>
            <meta name="description" content={jobMetaDataOverview.sscGd.description} />
            <meta name="keywords" content={jobMetaDataOverview.sscGd.keywords} />
          </Helmet>
          <SscGd />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(sscJobLinks.sscStenographer?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.sscStenographer.title}</title>
            <meta name="description" content={jobMetaDataOverview.sscStenographer.description} />
            <meta name="keywords" content={jobMetaDataOverview.sscStenographer.keywords} />
          </Helmet>
          <SscStenographer />
        </>
      } 
    />
    
    {/* Defence Sector */}
    <Route 
      path={removeJobPrefix(defenceJobLinks.afcat?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.afcat.title}</title>
            <meta name="description" content={jobMetaDataOverview.afcat.description} />
            <meta name="keywords" content={jobMetaDataOverview.afcat.keywords} />
          </Helmet>
          <AirForceCommonAdmissionTest />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(defenceJobLinks.cds?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.cds.title}</title>
            <meta name="description" content={jobMetaDataOverview.cds.description} />
            <meta name="keywords" content={jobMetaDataOverview.cds.keywords} />
          </Helmet>
          <CombinedDefenceServices />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(defenceJobLinks.indianCoastGuard?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.indianCoastGuard.title}</title>
            <meta name="description" content={jobMetaDataOverview.indianCoastGuard.description} />
            <meta name="keywords" content={jobMetaDataOverview.indianCoastGuard.keywords} />
          </Helmet>
          <IndianCoastGuard />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(defenceJobLinks.inet?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.inet.title}</title>
            <meta name="description" content={jobMetaDataOverview.inet.description} />
            <meta name="keywords" content={jobMetaDataOverview.inet.keywords} />
          </Helmet>
          <IndianNavyEntranceTest />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(defenceJobLinks.indianNavySsr?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.indianNavySSR.title}</title>
            <meta name="description" content={jobMetaDataOverview.indianNavySSR.description} />
            <meta name="keywords" content={jobMetaDataOverview.indianNavySSR.keywords} />
          </Helmet>
          <IndianNavySsr />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(defenceJobLinks.territorialArmy?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.territorialArmy.title}</title>
            <meta name="description" content={jobMetaDataOverview.territorialArmy.description} />
            <meta name="keywords" content={jobMetaDataOverview.territorialArmy.keywords} />
          </Helmet>
          <IndianTerritorialArmyForce />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(defenceJobLinks.navalAcademy?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.navalAcademy.title}</title>
            <meta name="description" content={jobMetaDataOverview.navalAcademy.description} />
            <meta name="keywords" content={jobMetaDataOverview.navalAcademy.keywords} />
          </Helmet>
          <NavalAcademy />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(defenceJobLinks.nda?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.nda.title}</title>
            <meta name="description" content={jobMetaDataOverview.nda.description} />
            <meta name="keywords" content={jobMetaDataOverview.nda.keywords} />
          </Helmet>
          <Nda />
        </>
      } 
    />
    
    {/* Railways */}
    <Route 
      path={removeJobPrefix(railwaysJobLinks.rrbRpf?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.rrbRpf.title}</title>
            <meta name="description" content={jobMetaDataOverview.rrbRpf.description} />
            <meta name="keywords" content={jobMetaDataOverview.rrbRpf.keywords} />
          </Helmet>
          <Rpf />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(railwaysJobLinks.rrbNtpc?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.rrbNtpc.title}</title>
            <meta name="description" content={jobMetaDataOverview.rrbNtpc.description} />
            <meta name="keywords" content={jobMetaDataOverview.rrbNtpc.keywords} />
          </Helmet>
          <RrbNTPC />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(railwaysJobLinks.rrbAlp?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.rrbAlp.title}</title>
            <meta name="description" content={jobMetaDataOverview.rrbAlp.description} />
            <meta name="keywords" content={jobMetaDataOverview.rrbAlp.keywords} />
          </Helmet>
          <RrbAlpRecruitment />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(railwaysJobLinks.rrbGroupD?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.rrbGroupD.title}</title>
            <meta name="description" content={jobMetaDataOverview.rrbGroupD.description} />
            <meta name="keywords" content={jobMetaDataOverview.rrbGroupD.keywords} />
          </Helmet>
          <RrbGroupD />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(railwaysJobLinks.rrbJe?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.rrbJe.title}</title>
            <meta name="description" content={jobMetaDataOverview.rrbJe.description} />
            <meta name="keywords" content={jobMetaDataOverview.rrbJe.keywords} />
          </Helmet>
          <RailwayJuniorEngineer />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(railwaysJobLinks.rrbParamedical?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.rrbParamedical.title}</title>
            <meta name="description" content={jobMetaDataOverview.rrbParamedical.description} />
            <meta name="keywords" content={jobMetaDataOverview.rrbParamedical.keywords} />
          </Helmet>
          <RrbParamedical />
        </>
      } 
    />
    
    {/* UPSC */}
    <Route 
      path={removeJobPrefix(upscJobLinks.upscExam?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.upsc.title}</title>
            <meta name="description" content={jobMetaDataOverview.upsc.description} />
            <meta name="keywords" content={jobMetaDataOverview.upsc.keywords} />
          </Helmet>
          <UpscExam />
        </>
      } 
    />
    
    {/* Bihar */}
    <Route 
      path={removeJobPrefix(biharJobLinks.biharPoliceSi?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.biharPoliceSi.title}</title>
            <meta name="description" content={jobMetaDataOverview.biharPoliceSi.description} />
            <meta name="keywords" content={jobMetaDataOverview.biharPoliceSi.keywords} />
          </Helmet>
          <BiharPoliceSI />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(biharJobLinks.bpscAssistantTeacher?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.bpscAssistantTeacher.title}</title>
            <meta name="description" content={jobMetaDataOverview.bpscAssistantTeacher.description} />
            <meta name="keywords" content={jobMetaDataOverview.bpscAssistantTeacher.keywords} />
          </Helmet>
          <BpscAssistantTeacher />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(biharJobLinks.bsphcl?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.bspchcl.title}</title>
            <meta name="description" content={jobMetaDataOverview.bspchcl.description} />
            <meta name="keywords" content={jobMetaDataOverview.bspchcl.keywords} />
          </Helmet>
          <BsphclRecruitment />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(biharJobLinks.btscHealth?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.btscHealth.title}</title>
            <meta name="description" content={jobMetaDataOverview.btscHealth.description} />
            <meta name="keywords" content={jobMetaDataOverview.btscHealth.keywords} />
          </Helmet>
          <BtscHealthRecruitment />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(biharJobLinks.BiharDistrictCourtRecruitment?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.BiharDistrictCourtRecruitment.title}</title>
            <meta name="description" content={jobMetaDataOverview.BiharDistrictCourtRecruitment.description} />
            <meta name="keywords" content={jobMetaDataOverview.BiharDistrictCourtRecruitment.keywords} />
          </Helmet>
          <BiharDistrictCourtRecruitment />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(biharJobLinks.polytechnic?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.biharPolytechnic.title}</title>
            <meta name="description" content={jobMetaDataOverview.biharPolytechnic.description} />
            <meta name="keywords" content={jobMetaDataOverview.biharPolytechnic.keywords} />
          </Helmet>
          <Polytechnic />
        </>
      } 
    />
    
    {/* UP */}
    <Route 
      path={removeJobPrefix(upJobLinks.healthTraining?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.upHealthGnmAnm.title}</title>
            <meta name="description" content={jobMetaDataOverview.upHealthGnmAnm.description} />
            <meta name="keywords" content={jobMetaDataOverview.upHealthGnmAnm.keywords} />
          </Helmet>
          <UPHealthTraining />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(upJobLinks.upSssc?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.upSssc.title}</title>
            <meta name="description" content={jobMetaDataOverview.upSssc.description} />
            <meta name="keywords" content={jobMetaDataOverview.upSssc.keywords} />
          </Helmet>
          <UpSssc />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(upJobLinks.upParamedical?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.upParamedical.title}</title>
            <meta name="description" content={jobMetaDataOverview.upParamedical.description} />
            <meta name="keywords" content={jobMetaDataOverview.upParamedical.keywords} />
          </Helmet>
          <UPParamedicalEntrance />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(upJobLinks.upPoliceSIASi?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.upSiass.title}</title>
            <meta name="description" content={jobMetaDataOverview.upSiass.description} />
            <meta name="keywords" content={jobMetaDataOverview.upSiass.keywords} />
          </Helmet>
          <UPPoliceSIASI />
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(upJobLinks.upTet?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.upTet.title}</title>
            <meta name="description" content={jobMetaDataOverview.upTet.description} />
            <meta name="keywords" content={jobMetaDataOverview.upTet.keywords} />
          </Helmet>
          <UPTETExam />
        </>
      } 
    />
    
    {/* Banking Sector */}
    <Route 
      path={removeJobPrefix(pnbJobLinks.pnbApprentice?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.pnbApprentice.title}</title>
            <meta name="description" content={jobMetaDataOverview.pnbApprentice.description} />
            <meta name="keywords" content={jobMetaDataOverview.pnbApprentice.keywords} />
          </Helmet>
          <PnbApprentice />
        </>
      } 
    />
    {/* Csirnet */}
    <Route 
      path={removeJobPrefix(ntaJobLinks.csirNet?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.CsirNetExam.title}</title>
            <meta name="description" content={jobMetaDataOverview.CsirNetExam.description} />
            <meta name="keywords" content={jobMetaDataOverview.CsirNetExam.keywords} />
          </Helmet>
          <CsirNet/>
        </>
      } 
    />
    <Route 
      path={removeJobPrefix(upJobLinks.upAgriculture?.url)} 
      element={
        <>
          <Helmet>
            <title>{jobMetaDataOverview.upAgricultureservice.title}</title>
            <meta name="description" content={jobMetaDataOverview.upAgricultureservice.description} />
            <meta name="keywords" content={jobMetaDataOverview.upAgricultureservice.keywords} />
          </Helmet>
          <UppscAgricultureServices/>
        </>
      } 
    />
    
  </Routes>
);

export default JobRoutes;
