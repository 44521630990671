import React from 'react'

const Disclaimer = () => {
  return (
    <section className='flex justify-center mb-6'>
    <div className="p-2 bg-white border rounded-md w-[80%] md:w-[90%]">
    <p className="text-sm font-semibold text-red-700 ">Disclaimer:</p>
    <p className="text-red-700 text-sm">
    The examination results/marks published on this website are provided for immediate informational purposes and do not constitute a legal document. While we have made every effort to ensure the accuracy of the data, we cannot guarantee its completeness. We are not liable for any inadvertent errors, inaccuracies, or any loss or damage resulting from such defects.
    </p>
  </div>
  </section>
  )
}
export default Disclaimer;