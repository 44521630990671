import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const UPParamedicalEntrance = () => {
    const dateData = [
        { rowElement: 'Application Begin', rowElementresult: '20 January 2024'},
        { rowElement: 'Last Date for Apply Online', rowElementresult: '18 June 2024' },
        { rowElement: 'Pay Exam Fee Last Date', rowElementresult: '18 June 2024' },
        { rowElement: 'Exam Date', rowElementresult: '30 June 2024' },
        { rowElement: 'Admit Card Available', rowElementresult: '25 June 2024' },
        { rowElement: 'Result Available', rowElementresult: '23 July 2024' },
    ];

    const applicationFeeData = [
        { rowElement: 'General / OBC / EWS Fee', rowElementresult: '₹3000' },
        { rowElement: 'SC / ST / PH Fee', rowElementresult: '₹2000' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Debit Card / Credit Card / Net Banking Only' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '17 Years' },
        { rowElement: 'Maximum Age for Paramedical UG Courses', rowElementresult: '30 Years (SGPGIMS Lucknow)' },
        { rowElement: 'Maximum Age', rowElementresult: 'As per Notification' },
        { rowElement: 'Age Relaxation', rowElementresult: 'As per ABVMU CPET 2024 Rules' },
    ];

    const vacancyDetails = [
        { rowElement: 'Bachelor in Medical Laboratory Science (BMLS)', rowElementresult: '4 years' },
        { rowElement: 'Bachelor of Optometry (BOptom)', rowElementresult: '4 years' },
        { rowElement: 'B.Sc. in Operation Theater Technology (BOTT)', rowElementresult: '4 years' },
        { rowElement: 'B.Sc. in Medical Radiology & Imaging Technology (BMRIT)', rowElementresult: '4 years' },
        { rowElement: 'Bachelor in Audiology and Speech Language Pathology (BASLP)', rowElementresult: '4 years' },
        { rowElement: 'Bachelor of Physiotherapy (BPT)', rowElementresult: '4 ½ years' },
        { rowElement: 'Bachelor of Occupational Therapy (BOT)', rowElementresult: '4 ½ years' },
        { rowElement: 'Master’s in Medical Laboratory Science (MMLS)', rowElementresult: '2 years' },
        { rowElement: 'Master of Optometry (MOptom)', rowElementresult: '2 years' },
        { rowElement: 'M.Sc. in Operation Theatre Technology (MOTT)', rowElementresult: '2 years' },
        { rowElement: 'M.Sc. in Medical Radiology & Imaging Technology (MMRIT)', rowElementresult: '2 years' },
        { rowElement: 'Master of Physiotherapy (MPT)', rowElementresult: '2 years' },
        { rowElement: 'Master of Occupational Therapy (MOT)', rowElementresult: '2 years' },
    ];

    const eligibilityData = [
        { rowElement: 'UG Courses', rowElementresult: '10+2 Intermediate with relevant subjects as per the course' },
        { rowElement: 'PG Courses', rowElementresult: 'Bachelor’s Degree in relevant field' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
        { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
    ];

    const importantLinkData = [
        { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'https://abvmucet2024.co.in/' },
        { rowElement: 'Download Notification', rowElementresult: 'Click Here', resultLink: 'https://abvmu24.blr1.cdn.digitaloceanspaces.com/ABVMU2024-Prod/2024/Documents/PARA%20BACHELOR%20CPET%202024%20INFORMATION%20BROCHURE%20FINAL.pdf' },
        { rowElement: 'Download Admit Card', rowElementresult: 'Click Here', resultLink: 'https://abvmucet2024.co.in/' },
        { rowElement: 'Download Result', rowElementresult: 'Click Here', resultLink: 'https://abvmucet2024.co.in/' },
    ];
    const categoryWiseVacancyDetail = [
        { postName:'up para mrdical' ,ur:0,sc:0,st:0,obc:0,ews:0}
    ];

    return (
        <Createnewjob 
            URL='https://abvmucet2024.co.in'
            mainHead={"ABVMU UP Common Paramedical Entrance Test (CPET) 2024"}
            head={"UP CPET 2024 Notification and Details"}
            dateData={dateData}  
            applicationFeeData={applicationFeeData} 
            ageLimitData={ageLimitData} 
            vacancyDetails={vacancyDetails} 
            eligibilityData={eligibilityData}
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
            importantLinkData={importantLinkData} 
        />
    );
};

export default UPParamedicalEntrance;