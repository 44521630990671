import React from 'react'
import SliderLink from './SliderLink';
const TextSlider = () => {
  return (
    
    <>
    <div className='relative overflow-hidden '>
<SliderLink 
link1={'/job/rrb-ntpc-non-technical-popular-categories'} linkLabel1={' Latest news about RRB NTPC 2024  '}
link2={'/job/pnb-apprentice-punjab-national-bank'} linkLabel2={'Pnb Apprentice 2024 result released see now'}
link3={'/job/ssc-gd'} linkLabel3={' Apply for SSC GD 2025 exam now  '}
textSlide={'textSlider'}
/>
<SliderLink 
link1={'/job/ssc-cgl'} linkLabel1={'Know latest news about SSC CGl Exam 2024'}
link2={'/job/bpsc-assistant-teacher'} linkLabel2={'Know details about bpsc 2024 exam  '}
link3={'/job/rrb-group-d'} linkLabel3={'Know details about RRB GROUP D 2024 exam  '}
textSlide={'textSliderfast'}
/>
<SliderLink 
link1={'https://www.youtube.com/@sarkarilock1'} linkLabel1={'Follow us on YOUTUBE for latest updates'}
link2={'https://www.facebook.com/profile.php?id=61564607174252&mibextid=ZbWKwL'} linkLabel2={'Follow us on FACEBOOK for latest updates'}
link3={'https://www.instagram.com/@sarkarilock?igsh=MW1hNDV6cW11ZjBia=='} linkLabel3={'Follow us on INSTAGRAM for latest updates'}
textSlide={'textSliderfaster'}
/>

    </div>
    </>
  )
}
export default TextSlider;