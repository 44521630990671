import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

const Nda = () => {
  const dateData = [
    { rowElement: 'Application Start On', rowElementresult: 'END' },
    { rowElement: 'Application Deadline', rowElementresult: 'END' },
    { rowElement: 'Admit Card Release Date', rowElementresult: '26 September 2024' },
    { rowElement: 'Exam Date', rowElementresult: '30 September 2024 to 14 November 2024' },
  ];

  // Dummy Data for Age Limit
  const ageLimitData = [
    { rowElement: 'Minimum Age', rowElementresult: '16.5 Years' },
    { rowElement: 'Maximum Age', rowElementresult: '19.5 Years' },
    { rowElement: 'Maximum Age for certain posts', rowElementresult: 'N/A' },
  ];

  // Dummy Data for Vacancy Details
  const vacancyDetails = [
    { rowElement: 'NDA', rowElementresult: '50 to 150' },
    { rowElement: 'Army wing(SEAT)', rowElementresult: '208 Seats' },
    { rowElement: 'Navy wing(SEAT)', rowElementresult: '42 Seats' },
    { rowElement: 'Air Force wing(SEAT)', rowElementresult: '120 Seats' },
    { rowElement: 'Naval Academy (10+2 Cadet Entry Scheme) (SEAT)', rowElementresult: '30 Seats' },
  ];

  // Dummy Data for Eligibility Criteria
  const eligibilityData = [
    { rowElement: 'Educational Qualification', rowElementresult: 'Minimum 10th and 12th passed from their own Board' },
    { rowElement: 'Experience Required', rowElementresult: 'No Experience Required' },
    { rowElement: 'Nationality', rowElementresult: 'Indian' },
    { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
  ];

  // Dummy Data for Application Fee
  const applicationFeeData = [
    { rowElement: 'General/OBC Fee', rowElementresult: '₹100' },
    { rowElement: 'SC/ST Fee', rowElementresult: '₹00' },
    { rowElement: 'Application Fee for Females', rowElementresult: '₹00' },
    { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
  ];

  // Dummy Data for Important Links
  const importantLinkData = [
    { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://upsc.gov.in/examinations/National%20Defence%20Academy%20and%20Naval%20Academy%20Examination%20%28I%29%2C%202024' },
    { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://nda.nic.in/' },
    { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://upsc.gov.in/examinations/National%20Defence%20Academy%20and%20Naval%20Academy%20Examination%20%28I%29%2C%202024' },
    { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://upsc.gov.in/examinations/National%20Defence%20Academy%20and%20Naval%20Academy%20Examination%20%28I%29%2C%202024' },
  ];

  // Dummy Data for Category-wise Vacancy Detail
  const categoryWiseVacancyDetail = [
    { postName: 'NDA', ur: '00', sc: '00', st: '00', obc: '00', ews: '00' },
  ];



  return (
    <>
      <Createnewjob 
        URL=''
        mainHead="National Defence Academy (NDA) Result"
        head="National Defence Academy (NDA) Result"
        ageLimitData={ageLimitData}
        eligibilityData={eligibilityData}
        dateData={dateData}
        importantLinkData={importantLinkData}
        applicationFeeData={applicationFeeData}
        vacancyDetails={vacancyDetails}
        categoryWiseVacancyDetail={categoryWiseVacancyDetail}
      />
    </>
  );
};

export default Nda;
