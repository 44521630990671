
const jobMetaDataOverview = {
    sscCgl: {
      title: "SSC-CGL 2024: Latest Updates and Information",
      description: "Stay updated with the latest information on SSC-CGL 2024. Find details about exam dates, syllabus, eligibility, and application process.",
      keywords: "SSC-CGL 2024, SSC CGL Exam Dates, SSC CGL Eligibility, SSC CGL Syllabus, SSC CGL Application"
    },
    sscChsl: {
      title: "SSC-CHSL 2024: Latest Updates and Information",
      description: "Get the latest updates on SSC-CHSL 2024. Explore details on exam dates, syllabus, eligibility criteria, and application process.",
      keywords: "SSC-CHSL 2024, SSC CHSL Exam Dates, SSC CHSL Eligibility, SSC CHSL Syllabus, SSC CHSL Application"
    },
    sscGd: {
      title: "SSC-GD 2024: Latest Updates and Information",
      description: "Discover the latest information about SSC-GD 2024. Find out about exam dates, syllabus, eligibility, and how to apply.",
      keywords: "SSC-GD 2024, SSC GD Exam Dates, SSC GD Eligibility, SSC GD Syllabus, SSC GD Application"
    },
    sscStenographer: {
      title: "SSC-Stenographer 2024: Latest Updates and Information",
      description: "Stay informed with the latest updates on SSC-Stenographer 2024. Get details on exam dates, syllabus, eligibility, and application process.",
      keywords: "SSC-Stenographer 2024, SSC Stenographer Exam Dates, SSC Stenographer Eligibility, SSC Stenographer Syllabus, SSC Stenographer Application"
    },
    sscMts: {
      title: "SSC-MTS 2024: Latest Updates and Information",
      description: "Find the latest updates on SSC-MTS 2024. Get comprehensive details about exam dates, syllabus, eligibility, and application process.",
      keywords: "SSC-MTS 2024, SSC MTS Exam Dates, SSC MTS Eligibility, SSC MTS Syllabus, SSC MTS Application"
    },
    afcat: {
      title: "AFCAT 2024: Latest Updates and Information",
      description: "Get the latest updates on AFCAT 2024, including exam dates, syllabus, eligibility criteria, and application details.",
      keywords: "AFCAT 2024, Air Force Common Admission Test, AFCAT Exam Dates, AFCAT Eligibility, AFCAT Application"
    },
    cds: {
      title: "CDS 2024: Latest Updates and Information",
      description: "Stay updated with the latest information on CDS 2024. Find details about exam dates, syllabus, eligibility, and application process.",
      keywords: "CDS 2024, Combined Defence Services, CDS Exam Dates, CDS Eligibility, CDS Application"
    },
    indianCoastGuard: {
      title: "Indian Coast Guard 2024: Latest Updates and Information",
      description: "Get the latest updates on Indian Coast Guard 2024. Explore details about exam dates, syllabus, eligibility criteria, and application process.",
      keywords: "Indian Coast Guard 2024, Coast Guard Exam Dates, Indian Coast Guard Eligibility, Coast Guard Syllabus, Coast Guard Application"
    },
    inet: {
      title: "INET 2024: Latest Updates and Information",
      description: "Discover the latest updates on INET 2024, including exam dates, syllabus, eligibility criteria, and application process.",
      keywords: "INET 2024, Indian Navy Entrance Test, INET Exam Dates, INET Eligibility, INET Application"
    },
    indianNavySSR: {
      title: "Indian Navy SSR 2024: Latest Updates and Information",
      description: "Find the latest updates on Indian Navy SSR 2024. Get information about exam dates, syllabus, eligibility, and application details.",
      keywords: "Indian Navy SSR 2024, SSR Exam Dates, Indian Navy SSR Eligibility, SSR Syllabus, Indian Navy SSR Application"
    },
    territorialArmy: {
      title: "Territorial Army 2024: Latest Updates and Information",
      description: "Get the latest updates on Territorial Army 2024, including exam dates, syllabus, eligibility criteria, and application process.",
      keywords: "Territorial Army 2024, Territorial Army Exam Dates, Territorial Army Eligibility, Territorial Army Syllabus, Territorial Army Application"
    },
    navalAcademy: {
      title: "Naval Academy 2024: Latest Updates and Information",
      description: "Stay informed with the latest updates on Naval Academy 2024. Find details about exam dates, syllabus, eligibility, and application process.",
      keywords: "Naval Academy 2024, Naval Academy Exam Dates, Naval Academy Eligibility, Naval Academy Syllabus, Naval Academy Application"
    },
    nda: {
      title: "NDA 2024: Latest Updates and Information",
      description: "Get the latest updates on NDA 2024, including exam dates, syllabus, eligibility criteria, and application details.",
      keywords: "NDA 2024, National Defence Academy, NDA Exam Dates, NDA Eligibility, NDA Application"
    },
    railwaysRpf: {
      title: "Railways RPF Exam 2024: Latest Updates and Information",
      description: "Find the latest updates on Railways RPF Exam 2024. Explore details about exam dates, syllabus, eligibility, and application process.",
      keywords: "Railways RPF 2024, RPF Exam Dates, Railway Protection Force Eligibility, RPF Syllabus, RPF Application"
    },
    rrbAlp: {
      title: "RRB Assistant Loco Pilot Recruitment 2024: Latest Updates",
      description: "Get the latest updates on RRB Assistant Loco Pilot Recruitment 2024. Find information on exam dates, syllabus, eligibility, and application process.",
      keywords: "RRB Assistant Loco Pilot 2024, ALP Recruitment Dates, RRB ALP Eligibility, ALP Syllabus, ALP Application"
    },
    rrbJe: {
      title: "RRB Junior Engineer Exam 2024: Latest Updates",
      description: "Stay updated with the latest information on RRB Junior Engineer Exam 2024. Get details about exam dates, syllabus, eligibility, and application process.",
      keywords: "RRB Junior Engineer 2024, Junior Engineer Exam Dates, RRB JE Eligibility, JE Syllabus, JE Application"
    },
    rrbRpf: {
      title: "RRB Railway Protection Force Exam 2024: Latest Updates",
      description: "Get the latest updates on RRB Railway Protection Force Exam 2024. Find details on exam dates, syllabus, eligibility, and application process.",
      keywords: "RRB Railway Protection Force 2024, RPF Exam Dates, RPF Eligibility, Railway Protection Force Syllabus, RPF Application"
    },
    rrbNtpc: {
      title: "RRB Non-Technical Popular Categories Exam 2024: Latest Updates",
      description: "Find the latest updates on RRB NTPC Exam 2024. Explore information on exam dates, syllabus, eligibility, and application process.",
      keywords: "RRB NTPC 2024, Non-Technical Popular Categories Exam, NTPC Exam Dates, NTPC Eligibility, NTPC Syllabus"
    },
    rrbGroupD: {
      title: "RRB Group D Recruitment 2024: Latest Updates",
      description: "Stay informed with the latest updates on RRB Group D Recruitment 2024. Get details about exam dates, syllabus, eligibility, and application process.",
      keywords: "RRB Group D 2024, Group D Recruitment Dates, RRB Group D Eligibility, Group D Syllabus, Group D Application"
    },
    rrbParamedical: {
      title: "RRB Paramedical Recruitment 2024: Latest Updates",
      description: "Get the latest updates on RRB Paramedical Recruitment 2024, including exam dates, syllabus, eligibility criteria, and application details.",
      keywords: "RRB Paramedical 2024, Paramedical Recruitment Dates, RRB Paramedical Eligibility, Paramedical Syllabus, Paramedical Application"
    },
    biharPoliceSi: {
      title: "Bihar Police SI Sub-Inspector Recruitment 2024: Latest Updates",
      description: "Stay updated with the latest information on Bihar Police SI Sub-Inspector Recruitment 2024. Find details about exam dates, syllabus, eligibility, and application process.",
      keywords: "Bihar Police SI 2024, Sub-Inspector Recruitment Dates, Bihar Police SI Eligibility, SI Syllabus, SI Application"
    },
    bpscAssistantTeacher: {
      title: "BPSC Assistant Teacher Recruitment 2024: Latest Updates",
      description: "Get the latest updates on BPSC Assistant Teacher Recruitment 2024, including exam dates, syllabus, eligibility criteria, and application details.",
      keywords: "BPSC Assistant Teacher 2024, Assistant Teacher Recruitment Dates, BPSC Exam Dates, Assistant Teacher Eligibility, Teacher Application"
    },
    bspchcl: {
      title: "BSPHCL Recruitment 2024: Latest Updates",
      description: "Find the latest updates on BSPHCL Recruitment 2024. Explore information on exam dates, syllabus, eligibility criteria, and application process.",
      keywords: "BSPHCL 2024, Bihar State Power Holding Company Limited Recruitment, BSPHCL Exam Dates, BSPHCL Eligibility, BSPHCL Application"
    },
    btscHealth: {
      title: "BTSC Health Recruitment 2024: Latest Updates",
      description: "Stay informed with the latest updates on BTSC Health Recruitment 2024. Get details about exam dates, syllabus, eligibility, and application process.",
      keywords: "BTSC Health 2024, Bihar Technical Service Commission Recruitment, BTSC Exam Dates, Health Recruitment Eligibility, BTSC Application"
    },
    biharPolytechnic: {
      title: "Bihar Polytechnic Recruitment 2024: Latest Updates",
      description: "Get the latest updates on Bihar Polytechnic Recruitment 2024 including exam dates, syllabus, eligibility, and more.",
      keywords: "Bihar Polytechnic 2024, Polytechnic Recruitment, Bihar Polytechnic Exam Dates, Polytechnic Eligibility"
    },
    BiharDistrictCourtRecruitment: {
      title: "Bihar District Court Recruitment 2024: Latest Updates and Notifications",
      description: "Get the latest updates on Bihar District Court Recruitment 2024, including vacancy details, exam dates, eligibility criteria, and selection process.",
      keywords: "Bihar District Court Recruitment 2024, Bihar Court Jobs, District Court Vacancy, Bihar Court Exam Dates, Bihar Court Eligibility"
    },
    upHealthGnmAnm: {
      title: "UP GNM/ANM Health Training 2024: Latest Updates",
      description: "Get the latest updates on UP GNM/ANM Health Training 2024 including exam dates, syllabus, eligibility, and more.",
      keywords: "UP GNM/ANM 2024, GNM ANM Health Training, UP Health Training Exam Dates, GNM ANM Eligibility"
    },
    upParamedical: {
      title: "UP Paramedical Recruitment 2024: Latest Updates",
      description: "Get the latest updates on UP Paramedical Recruitment 2024 including exam dates, syllabus, eligibility, and more.",
      keywords: "UP Paramedical 2024, Paramedical Recruitment, UP Paramedical Exam Dates, Paramedical Eligibility"
    },
    upSiass: {
      title: "UP SIAS-I (Senior Industrial Assistant Sub-Inspector) Recruitment 2024: Latest Updates",
      description: "Get the latest updates on UP SIAS-I Recruitment 2024 including exam dates, syllabus, eligibility, and more.",
      keywords: "UP SIAS-I 2024, Senior Industrial Assistant Recruitment, UP SIAS-I Exam Dates, SIAS-I Eligibility"
    },
    upSssc: {
      title: "UPSSSC Gram Panchayat Adhikari, Gram Vikas Adhikari, and Samaj Kalyan Prayveksh Recruitment 2018: Complete Details",
      description: "Find comprehensive information on the UPSSSC Recruitment 2018 for Gram Panchayat Adhikari, Gram Vikas Adhikari, and Samaj Kalyan Prayveksh, including exam details, eligibility, and other important updates.",
      keywords: "UPSSSC 2018, Gram Panchayat Adhikari Recruitment, Gram Vikas Adhikari Exam, Samaj Kalyan Prayveksh Eligibility, UPSSSC Exam Details"
    },
    
  
    upTet: {
      title: "UP TET (Teacher Eligibility Test) Exam 2024: Latest Updates",
      description: "Get the latest updates on UP TET Exam 2024 including exam dates, syllabus, eligibility, and more.",
      keywords: "UP TET 2024, Teacher Eligibility Test, UP TET Exam Dates, TET Eligibility"
    },
    upsc: {
      title: "UPSC 2024: Latest Updates and Information",
      description: "Stay updated with the latest information on UPSC 2024. Find details about exam dates, syllabus, eligibility, and application process.",
      keywords: "UPSC 2024, Union Public Service Commission, UPSC Exam Dates, UPSC Eligibility, UPSC Application"
    },
    pnbApprentice: {
      title: "PNB Apprentice Exam 2024: Latest Updates",
      description: "Get the latest updates on PNB Apprentice Exam 2024 including exam dates, syllabus, eligibility, and more.",
      keywords: "PNB Apprentice 2024, PNB Apprentice Exam, Apprentice Exam Dates, PNB Eligibility"
    },

    // nta
    CsirNetExam: {
      title: "CSIR NET Exam 2024: Latest Updates, Syllabus, and Eligibility",
      description: "Stay informed about CSIR NET Exam 2024, including important dates, syllabus, eligibility criteria, and preparation tips.",
      keywords: "CSIR NET 2024, CSIR NET Exam Dates, CSIR NET Syllabus, CSIR NET Eligibility, CSIR NET Preparation"
    },
    upAgricultureservice:{
      title:"UP Agriculture service pre result 2024, syllabus,latest update",
      description:"Stay informed about UP Based eaxam and for latest job and vaccancy, including important dates, syllabus, eligibility criteria, and preparation tips.",
      keyboard:"up agriculture result,up agriculture eligblity,up agriculture update"

    },
    
  };
  
  export default jobMetaDataOverview;
  