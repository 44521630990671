import React from 'react'
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const UPHealthTraining = () => {
    const dateData = [
        { rowElement: 'Application Begin', rowElementresult: '01 July 2024' },
        { rowElement: 'Last Date for Apply Online', rowElementresult: '31 July 2024' },
        { rowElement: 'Pay Exam Fee Last Date', rowElementresult: '31 July 2024' },
        { rowElement: 'Merit List', rowElementresult: '30 August 2024' },
    ];

    const applicationFeeData = [
        { rowElement: 'General Fee', rowElementresult: '₹200' },
        { rowElement: 'OBC/SC/ST Fee', rowElementresult: '₹100' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Debit Card / Credit Card / Net Banking Only' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '17 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '35 Years' },
        { rowElement: 'Age Relaxation', rowElementresult: 'As per NHM UP GNM & ANM Training Post Admission Rules 2024-25' },
    ];

    const vacancyDetails = [
        { rowElement: 'Total Seats', rowElementresult: '2253' },
        { rowElement: 'GNM Seats', rowElementresult: '453' },
        { rowElement: 'ANM Seats', rowElementresult: '1800' },
    ];

    const eligibilityData = [
        { rowElement: 'GNM', rowElementresult: '10+2 Intermediate & High School Exam Passed with Science Stream and English as a Subject with 40% Marks; OR ANM with Registration in Council' },
        { rowElement: 'ANM', rowElementresult: 'Only for Female Candidates; 10+2 Intermediate in Any Recognized Board; General/OBC/EWS: 45% Marks; SC/ST: 40% Marks' },
    ];

    const importantLinkData = [
        { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download Notification', rowElementresult: 'Click Here', resultLink: 'URL' },
        
    ];

    const categoryWiseVacancyDetail = [
        { trainingProgram: 'GNM', totalSeats: 453 },
        { trainingProgram: 'ANM', totalSeats: 1800 },
    ];

    return (
      <Createnewjob 
        URL='HTTP:// URL'
        mainHead={"Uttar Pradesh GNM / ANM Training 2024 Registration"}
        head={"UP GNM / ANM Training 2024 Notification"}
        dateData={dateData}  
        applicationFeeData={applicationFeeData} 
        ageLimitData={ageLimitData} 
        vacancyDetails={vacancyDetails} 
        eligibilityData={eligibilityData} 
        importantLinkData={importantLinkData} 
        categoryWiseVacancyDetail={categoryWiseVacancyDetail}
      />
    )
}

export default UPHealthTraining;
