import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const BiharDistrictCourtRecruitment = () => {
    const dateData = [
        { rowElement: 'Application Begin', rowElementresult: '20 September 2022' },
        { rowElement: 'Last Date for Apply Online', rowElementresult: '20 October 2022' },
        { rowElement: 'Pay Exam Fee Last Date', rowElementresult: '20 October 2022' },
        { rowElement: 'Court Reader, Steno Exam Date', rowElementresult: '17 December 2023' },
        { rowElement: 'Admit Card Available', rowElementresult: '10 December 2023' },
        { rowElement: 'Court Reader Final Result', rowElementresult: '12 September 2024' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age for Peon', rowElementresult: '18 Years' },
        { rowElement: 'Minimum Age for Other Posts', rowElementresult: '21 Years' },
        { rowElement: 'Maximum Age for Male', rowElementresult: '37 Years' },
        { rowElement: 'Maximum Age for Female', rowElementresult: '40 Years' },
        { rowElement: 'Age Relaxation', rowElementresult: 'As per Bihar District/Civil Court Recruitment Rules' },
    ];

    const eligibilityData = [
        { rowElement: 'Clerk', rowElementresult: 'Bachelor Degree in Any Stream with Computer Knowledge' },
        { rowElement: 'Stenographer', rowElementresult: 'Bachelor Degree with Stenographer Certificate and Computer Proficiency; English Shorthand 80 WPM, Hindi Shorthand 60 WPM, Typing English 40 WPM, Hindi 30 WPM' },
        { rowElement: 'Court Reader Cum Deposition Writer', rowElementresult: 'Bachelor Degree with Certificate of English and Hindi Typing' },
        { rowElement: 'Peon / Orderly (Group D)', rowElementresult: 'Class 10th Matric Exam Passed' },
    ];

    const applicationFeeData = [
        { rowElement: 'General / OBC / EWS Fee', rowElementresult: '₹800' },
        { rowElement: 'SC / ST Fee', rowElementresult: '₹400' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Debit Card / Credit Card / Net Banking Only' },
    ];

    const vacancyDetails = [
        { rowElement: 'Total Vacancies', rowElementresult: '7692' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'Clerk', total: 3325 },
        { postName: 'Stenographer', total: 1562 },
        { postName: 'Court Reader Cum Deposition Writer', total: 1132 },
        { postName: 'Peon / Orderly (Group D)', total: 1673 },
    ];

    const importantLinkData = [
        { rowElement: 'Download Court Reader Final Result', rowElementresult: 'Click Here', resultLink: 'https://cdnbbsr.s3waas.gov.in/s3ec05a18630ab1c3b9f14454cf70dc711/uploads/2024/09/2024091211.pdf' },
        { rowElement: 'Download Admit Card', rowElementresult: 'Click Here', resultLink: 'https://cdnbbsr.s3waas.gov.in/s3ec05a18630ab1c3b9f14454cf70dc711/uploads/2024/09/2024091211.pdf' },
        { rowElement: 'Download Admit Card Notice', rowElementresult: 'Click Here', resultLink: 'https://districts.ecourts.gov.in/sites/default/files/Notice%20dated%2025-09-2023%20-%20Date%20of%20Examination%20for%20the%20post%20of%20Stenographer%20and%20Court%20Reader-cum-Deposit.pdf' },
        { rowElement: 'Download Exam Notice', rowElementresult: 'Click Here', resultLink: 'https://districts.ecourts.gov.in/sites/default/files/Notice%20dated%2025-09-2023%20-%20Date%20of%20Examination%20for%20the%20post%20of%20Stenographer%20and%20Court%20Reader-cum-Deposit.pdf' },
        { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'https://dcprequirement.in/' },
        { rowElement: 'Download Short Notice', rowElementresult: 'English | Hindi', resultLink: 'https://districts.ecourts.gov.in/sites/default/files/Notice%20dated%2012.09.2022_English.pdf' },
        { rowElement: 'Patna Civil Court Official Website', rowElementresult: 'Click Here', resultLink: 'https://patna.dcourts.gov.in/' },
    ];

    return (
        <Createnewjob 
            URL='https://patna.dcourts.gov.in/' // Replace with actual URL
            mainHead={"Bihar District Court Clerk, Stenographer, Court Reader, and Peon Recruitment 2022"}
            head={"Bihar District Court Recruitment 2022 Details"}
            ageLimitData={ageLimitData}
            eligibilityData={eligibilityData}
            dateData={dateData}
            importantLinkData={importantLinkData}
            applicationFeeData={applicationFeeData}
            vacancyDetails={vacancyDetails}
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
};

export default BiharDistrictCourtRecruitment;
