import React from 'react'
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const UPPoliceSIASI = () => {
    const dateData = [
        { rowElement: 'Application Begin', rowElementresult: '07 January 2024' },
        { rowElement: 'Last Date for Apply Online', rowElementresult: '31 January 2024' },
        { rowElement: 'Last Date Pay Exam Fee', rowElementresult: '31 January 2024' },
        { rowElement: 'Correction Last Date', rowElementresult: '01-02 February 2024' },
        { rowElement: 'Exam Date', rowElementresult: 'As per Schedule' },
        { rowElement: 'Admit Card Available', rowElementresult: 'Before Exam' },
    ];

    const applicationFeeData = [
        { rowElement: 'General/OBC/EWS Fee', rowElementresult: '₹400' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹400' },
        { rowElement: 'All Category Female', rowElementresult: '₹400' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Cash at E Challan or Debit Card, Credit Card, Net Banking' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '21 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '28 Years' },
        { rowElement: 'Age Between', rowElementresult: '01/07/1995 to 01/07/2002' },
        { rowElement: 'Age Relaxation', rowElementresult: 'As per UP Police PRPB Rules' },
    ];

    const vacancyDetails = [
        { rowElement: 'Total Posts', rowElementresult: '921' },
        { rowElement: 'UP Police SI Confidential', rowElementresult: '268 Posts' },
        { rowElement: 'UP Police ASI Clerk', rowElementresult: '449 Posts' },
        { rowElement: 'UP Police ASI Accounting', rowElementresult: '204 Posts' },
    ];

    const eligibilityData = [
        { rowElement: 'UP Police SI Confidential', rowElementresult: 'Bachelor Degree in Any Stream; Hindi Typing 25 WPM & English Typing 30 WPM; Stenographer Hindi: 80 WPM; O Level Exam Passed' },
        { rowElement: 'UP Police ASI Clerk', rowElementresult: 'Bachelor Degree in Any Stream; Hindi Typing 25 WPM & English Typing 30 WPM; O Level Exam Passed' },
        { rowElement: 'UP Police ASI Accounting', rowElementresult: 'Bachelor Degree in Commerce (B.Com); Hindi Typing 15 WPM; O Level Exam Passed' },
    ];

    const physicalEligibilityData = [
        { rowElement: 'Male General/OBC/SC Height', rowElementresult: '163 CMS' },
        { rowElement: 'Male ST Height', rowElementresult: '156 CMS' },
        { rowElement: 'Female General/OBC/SC Height', rowElementresult: '150 CMS' },
        { rowElement: 'Female ST Height', rowElementresult: '145 CMS' },
        { rowElement: 'Male Chest (GEN/OBC/SC)', rowElementresult: '77-82 CMS' },
        { rowElement: 'Male Chest (ST)', rowElementresult: '75-80 CMS' },
    ];

    const importantLinkData = [
        { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'How to Fill Form (Video Hindi)', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download Date Extended Notice', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download Notification', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download Syllabus', rowElementresult: 'Click Here', resultLink: 'URL' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'UP Police SI Confidential', ur: 114, ews: 25, obc: 71, sc: 54, st: 4 },
        { postName: 'UP Police ASI Clerk', ur: 186, ews: 43, obc: 120, sc: 93, st: 7 },
        { postName: 'UP Police ASI Accounting', ur: 88, ews: 19, obc: 53, sc: 42, st: 2 },
    ];

    return (
      <Createnewjob 
        URL='HTTP:// URL'
        mainHead={"UP Police SI Confidential, ASI Clerk & Accountant Recruitment 2023"}
        head={"UP Police SI/ASI Recruitment 2023-24 Notification"}
        dateData={dateData}  
        applicationFeeData={applicationFeeData} 
        ageLimitData={ageLimitData} 
        vacancyDetails={vacancyDetails} 
        eligibilityData={eligibilityData} 
        physicalEligibilityData={physicalEligibilityData} 
        importantLinkData={importantLinkData} 
        categoryWiseVacancyDetail={categoryWiseVacancyDetail}
      />
    )
}

export default UPPoliceSIASI;
