import React, { useState, useEffect } from 'react';
import './App.css';
import { HashRouter, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import BasicPagesRoutes from './components/basic-pages/BasicPagesRoutes'; 
import JobRoutes from './components/pages/all-jobs-overview/JobRoutes';

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
};

function App() {
  const [isActiveHamMenu, setIsActiveHamMenu] = useState(false);

  return (
    <HashRouter>
      <Header isActiveHamMenu={isActiveHamMenu} setIsActiveHamMenu={setIsActiveHamMenu} />

      <ScrollToTop />

      <div
        className={`transition-all duration-300 ease-in-out ${
          isActiveHamMenu ? 'blur-md inset-0 bg-slate-900' : ''
        }`}
        onClick={() => isActiveHamMenu && setIsActiveHamMenu(false)}
      >
        <Routes>
          {/* Main routes */}
          <Route path="/*" element={<BasicPagesRoutes />} />
          {/* Job routes under /job/ */}
          <Route path="/job/*" element={<JobRoutes />} />
        </Routes>
        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
