import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const SscMts = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: 'END' },
        { rowElement: 'Application Deadline', rowElementresult: 'END' },
        { rowElement: 'Admit Card Release Date', rowElementresult: '26 September 2024' },
        { rowElement: 'Exam Date', rowElementresult: '30 September 2024 to 14 November 2024' },
    ];
    
    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '18 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '25 Years' },
        { rowElement: 'Maximum Age for Certain Posts', rowElementresult: '18 to 27 Years' },
    ];
    
    const vacancyDetails = [
        { rowElement: 'SSC CHSL Grade A', rowElementresult: '9000-10000' },
        { rowElement: 'SSC CHSL Grade B', rowElementresult: '0' },
    ];
    
    const eligibilityData = [
        { rowElement: 'Educational Qualification', rowElementresult: 'Minimum 10th Passed' },
        { rowElement: 'Experience Required', rowElementresult: 'No Experience Required' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
        { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
    ];
    
    const applicationFeeData = [
        { rowElement: 'General/OBC Fee', rowElementresult: '₹100' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹00' },
        { rowElement: 'Application Fee for Females', rowElementresult: '₹100' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
    ];
    
    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://ssc.nic.in/Portal/Notices' },
        { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://ssc.nic.in/Portal/Apply' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://ssc.nic.in/Portal/AdmitCard' },
        { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://ssc.nic.in/Portal/Results' },
    ];
    
    const categoryWiseVacancyDetail = [
        { postName: 'SSC MTS ', ur: 5000, sc: 1500, st: 1000, obc: 3500, ews: 1000 },
    ];

    return (
        <Createnewjob
            URL='http://example.com'
            mainHead={"SSC MTS (Staff Selection Commission Multi-Tasking Staff) Examination 2024 Result"}
            head={"SSC MTS (Staff Selection Commission Multi-Tasking Staff) Examination 2024 Result"}
            ageLimitData={ageLimitData}
            eligibilityData={eligibilityData}
            dateData={dateData}
            importantLinkData={importantLinkData}
            applicationFeeData={applicationFeeData}
            vacancyDetails={vacancyDetails}
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
};

export default SscMts;
