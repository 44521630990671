import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

const Rpf = () => {
    const dateData = [
        { rowElement: 'Application Start Date', rowElementresult: '14 April 2024' },
        { rowElement: 'Last Date to Apply', rowElementresult: '14 May 2024' },
        { rowElement: 'Last Date for Fee Payment', rowElementresult: '18 – 20 May 2024' },
        { rowElement: 'Correction Date', rowElementresult: '15-24 May 2024' },
        { rowElement: 'Re Upload Photo / Signature', rowElementresult: '15-17 June 2024' },
        { rowElement: 'Exam Date', rowElementresult: 'Notified Soon' },
        { rowElement: 'Admit Card Release Date', rowElementresult: 'Notified Soon' },
    ];
    
    const ageLimitData = [
        { rowElement: 'RPF Constable Age Limit', rowElementresult: '18 – 28 Years' },
        { rowElement: 'RPF Sub-Inspector Age Limit', rowElementresult: '20 – 28 Years' },
    ];
    
    const vacancyDetails = [
        { rowElement: 'RPF Constable', rowElementresult: '4208 Posts' },
        { rowElement: 'RPF Sub-Inspector', rowElementresult: '452 Posts' },
    ];
    
    const eligibilityData = [
        { rowElement: 'RPF Constable', rowElementresult: 'Class 10th Passed' },
        { rowElement: 'RPF Sub-Inspector', rowElementresult: 'Bachelor’s Degree in Any Stream' },
    ];
    
    const physicalEligibilityData = [
        { rowElement: 'Constable (Male)', rowElementresult: '1600 Meter Running in 5 Min 45 Sec' },
        { rowElement: 'Constable (Female)', rowElementresult: '800 Meter Running in 3 Min 40 Sec' },
        { rowElement: 'Sub-Inspector (Male)', rowElementresult: '1600 Meter Running in 6 Min 30 Sec' },
        { rowElement: 'Sub-Inspector (Female)', rowElementresult: '800 Meter Running in 4 Min' },
    ];
    
    const physicalMeasurementData = [
        { rowElement: 'Male (UR/OBC)', rowElementresult: 'Height: 165 cm, Chest: 80 cm (Unexpand), 85 cm (Expanded)' },
        { rowElement: 'Male (SC/ST)', rowElementresult: 'Height: 160 cm, Chest: 76.2 cm (Unexpand), 81.2 cm (Expanded)' },
        { rowElement: 'Female (UR/OBC)', rowElementresult: 'Height: 157 cm' },
        { rowElement: 'Female (SC/ST)', rowElementresult: 'Height: 152 cm' },
    ];
    
    const applicationFeeData = [
        { rowElement: 'General/OBC/EWS Fee', rowElementresult: '₹500' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹250' },
        { rowElement: 'PH Fee', rowElementresult: '₹250' },
        { rowElement: 'Female Fee', rowElementresult: '₹250' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
    ];
    
    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://railwayrecruitmentboard.com/rpf-constable-si-notification-2024' },
        { rowElement: 'Apply Online', rowElementresult: 'Apply Here', resultLink: 'https://railwayrecruitmentboard.com/rpf-constable-si-apply-online' },
        { rowElement: 'Re Upload Photo/Signature', rowElementresult: 'Re Upload Here', resultLink: 'https://railwayrecruitmentboard.com/rpf-re-upload' },
        { rowElement: 'Correction Edit Form', rowElementresult: 'Edit Here', resultLink: 'https://railwayrecruitmentboard.com/rpf-correction-form' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'RPF Constable', ur: 2000, sc: 800, st: 400, obc: 1000, ews: 300 },
        { postName: 'RPF Sub-Inspector', ur: 150, sc: 50, st: 25, obc: 75, ews: 22 }
    ];

    return (
        <Createnewjob 
            mainHead={"Railway RPF Constable / SI Recruitment 2024"}
            head={"Railway RPF Constable / SI Recruitment 2024 Details"}
            ageLimitData={ageLimitData}
            eligibilityData={eligibilityData}
            dateData={dateData}
            importantLinkData={importantLinkData}
            applicationFeeData={applicationFeeData}
            vacancyDetails={vacancyDetails}
            physicalEligibilityData={physicalEligibilityData}
            physicalMeasurementData={physicalMeasurementData}
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
};

export default Rpf;
