import React from 'react';
import { Link } from 'react-router-dom';

export const SliderLink = ({ link1, link2, link3, linkLabel1, linkLabel2, linkLabel3, textSlide }) => {
  const wordLimit = (label) => {
    if (!label) {
      return "No label provided";
    }
    if (label.length >44 || label.length < 32) {
      return `Word limit is 32-44 including spaces. Your text has ${label.length} characters.`;
    } else {
      return label;
    }
  };

  return (
    <p className={`flex whitespace-nowrap text-blue-600 text-sm gap-1 ${textSlide} slideRight medium-screen slideRightLeft p-1`}>
      <Link to={link1} className='hover:underline border-r-2 border-slate-400 pr-1'>
        {wordLimit(linkLabel1)}
      </Link>
      <Link to={link2} className='hover:underline border-r-2 border-slate-400 pr-1'>
        {wordLimit(linkLabel2)}
      </Link>
      <Link to={link3} className='hover:underline'>
        {wordLimit(linkLabel3)}
      </Link>
    </p>
  );
};

export default SliderLink;
