import React from 'react';
import { FaFacebook,FaYoutube,FaInstagram } from "react-icons/fa";
import {FaXTwitter} from "react-icons/fa6";
import playStore from '../../assets/play-store.png';
import appStore from '../../assets/app-store.png';
import NavigationLink from './NavigationLink';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <section>
      <footer className='bg-gradient-to-tr from-lime-300 to-gray-50 h-ful p-10 flex flex-wrap justify-between pl-[5vw] pr-[5vw]'>
        <div className='flex flex-col gap-5 max-w-96'>
          <h1 className='bg-gradient-to-r from-orange-500 to-green-600 bg-clip-text text-transparent text-center font-bold text-3xl p-4 pt-4 cursor-pointer'>SarkariLock.IN</h1>
          <p>We are providing new and detailed information about sarkari jobs and sarkari yojanas through in-depth research.</p>
          <div className='p-5'>
            <span>Follow Us</span>
            <ul className='flex gap-3 mt-2 list-none'>
              <li>
              <a href="https://www.facebook.com/profile.php?id=61564607174252&mibextid=ZbWKwL" aria-label="Facebook" target='blank' className='hover:text-blue-500 cursor-pointer transition duration-300'>
                <FaFacebook />
              </a>
              </li>
              <li>
              <a  href="https://www.youtube.com/@sarkarilock1" aria-label="YouTube" target='blank' className='hover:text-red-500 cursor-pointer transition duration-300'>
                <FaYoutube />
              </a>
              </li>
              <li>
              <a  href="https://twitter.com/@sarkarilock" aria-label="Twitter" target='blank' className='hover:text-blue-400 cursor-pointer transition duration-300'>
                <FaXTwitter />
              </a>
              </li>
              <li>
              <a  href="https://www.instagram.com/sarkarilock/?utm_source=qr&igsh=MW1hNDV6cW11ZjBiaA%3D%3D" target='blank' aria-label="Twitter" className='hover:text-pink-500 cursor-pointer transition duration-300'>
                <FaInstagram />
              </a>
              </li>
             
            </ul>
          </div>
        </div>

        <div>
          <h3 className='text-lg font-semibold text-center pb-8'>Help & Contact Us</h3>
          <ul className='flex flex-col gap-2 list-none'>
            <NavigationLink path="/">Home</NavigationLink>
            <NavigationLink path="/about-us">About</NavigationLink>
            <NavigationLink path="/contact">Contact</NavigationLink>
            <NavigationLink path="/privacy-policy">Privacy & Policy</NavigationLink>
          </ul>
        </div>

        <div>
          <h3 className='text-lg font-semibold text-center pb-8'>Links</h3>
          <ul className='flex flex-col gap-2 list-none'>
            <NavigationLink path="/search-exam">Latest Job</NavigationLink>
            <NavigationLink path="/search-exam">Result</NavigationLink>
            <NavigationLink path="/comming-soon">Sarkari Yojana</NavigationLink>
            <NavigationLink path="/search-exam">Admit Card</NavigationLink>
          </ul>
        </div>

        <div>
          <h3 className='text-lg font-semibold text-center pb-8'>Exams</h3>
          <ul className='flex flex-col gap-2 list-none'>
            <NavigationLink path="/job/ssc-cgl-2024">Ssc CGL</NavigationLink>
            <NavigationLink path="/job/rrb-ntpc-204">Rrb Ntpc</NavigationLink>
            <NavigationLink path="/job/nda">NDA</NavigationLink>
            <NavigationLink path="/job/ssc-gd-2024">Ssc GD</NavigationLink>
          </ul>
        </div>

        <div>
          <h3 className='text-lg font-semibold text-center pb-5'>Install App</h3>
          <div className='flex gap-2 place-content-center'>
            <Link to="/comming-soon">
              <img
                src={playStore}
                alt="Play Store"
                className='w-32 bg-black border rounded-lg p-2 hover:scale-105 transition-transform duration-300 cursor-pointer'
              />
            </Link>
            <Link to="/comming-soon">
              <img
                src={appStore}
                alt="App Store"
                className='w-32 bg-black border rounded-lg p-2 hover:scale-105 transition-transform duration-300 cursor-pointer'
              />
            </Link>
          </div>
        </div>
      </footer>
      <p className='p-2 text-center justify-ceter bg-green-700 text-slate-100'>© 2024 SarkariLock.IN All rights reserved.</p>
    </section>
  );
}

export default Footer;
