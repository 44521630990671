import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const UpscExam = () => {
  const dateData = [
    { rowElement: 'Application Start On', rowElementresult: '14 February 2024' },
    { rowElement: 'Application Deadline', rowElementresult: '06 March 2024' },
    { rowElement: 'Last Date Pay Exam Fee', rowElementresult: '06 March 2024' },
    { rowElement: 'Correction Date', rowElementresult: '07-13 March 2024' },
    { rowElement: 'New Pre Exam Date', rowElementresult: '16 June 2024' },
    { rowElement: 'Pre Exam Result', rowElementresult: '01 July 2024' },
    { rowElement: 'Mains Exam Date', rowElementresult: '20-29 September 2024' },
  ];

  const ageLimitData = [
    { rowElement: 'Minimum Age', rowElementresult: '21 Years' },
    { rowElement: 'Maximum Age', rowElementresult: '32 Years' },
    { rowElement: 'Age Relaxation', rowElementresult: 'As per UPSC rules' },
  ];

  const vacancyDetails = [
    { rowElement: 'Indian Administrative Service (IAS)', rowElementresult: '1056' },
    { rowElement: 'Indian Forest Service (IFS)', rowElementresult: '150' },
  ];

  const eligibilityData = [
    { rowElement: 'IAS Educational Qualification', rowElementresult: 'Bachelor’s Degree in Any Stream from a Recognized University' },
    { rowElement: 'IFS Educational Qualification', rowElementresult: 'Bachelor’s Degree with one of the subjects - Animal Husbandry & Veterinary Science, Botany, Chemistry, Geology, Mathematics, Physics, Statistics, Zoology, Agriculture or Equivalent' },
    { rowElement: 'Nationality', rowElementresult: 'Indian' },
  ];
  const categoryWiseVacancyDetail = [
    { postName: 'Upsc all ', ur: 'N/A', sc:'N/A', st:'N/A', obc:'N/A', ews:'N/A' },
];
  const applicationFeeData = [
    { rowElement: 'General/OBC/EWS Fee', rowElementresult: '₹100' },
    { rowElement: 'SC/ST/PH Fee', rowElementresult: '₹0 (Exempted)' },
    { rowElement: 'Application Fee for Females', rowElementresult: '₹0 (Nil)' },
    { rowElement: 'Fee Payment Mode', rowElementresult: 'Debit Card, Credit Card, Net Banking, E-Challan' },
  ];

  const importantLinkData = [
    { rowElement: 'Download Civil Services IAS Mains Exam Schedule', rowElementresult: 'Click Here', resultLink: 'https://upsc.gov.in/sites/default/files/TT-CSM-24-engl-090824.pdf' },
    { rowElement: 'Download Pre Result (Name Wise)', rowElementresult: 'Civil Services | Forest Services', resultLink: 'https://upsc.gov.in/sites/default/files/WR-CSP-24-NameList-engl-190724.pdf,https://upsc.gov.in/sites/default/files/WR-NameList-IFSP-24-engl-190724.pdf' },
    { rowElement: 'Download Civil Services IAS Pre Result', rowElementresult: 'Click Here', resultLink: 'https://upsc.gov.in/WR-CSP-24-RollList-Engl-010724.pdf' },
    { rowElement: 'Download Forest Services IFS Pre Result', rowElementresult: 'Click Here', resultLink: 'https://upsc.gov.in/IFoSP_2024_WR_Eng_01072024.pdf' },
    { rowElement: 'Download Pre Admit Card', rowElementresult: 'Click Here', resultLink: 'https://upsconline.nic.in/eadmitcard/admitcard_csp_2024/' },
    { rowElement: 'Apply Online Through OTR', rowElementresult: 'Click Here', resultLink: 'https://upsconline.nic.in/upsc/OTRP/index.php' },
  ];

  return (
    <Createnewjob
    
      mainHead={"UPSC Civil Service / Forest Service IAS / IFS Recruitment 2024"}
      head={"UPSC Civil Services (IAS) and Indian Forest Service (IFS) Examination 2024"}
      ageLimitData={ageLimitData}
      eligibilityData={eligibilityData}
      dateData={dateData}
      importantLinkData={importantLinkData}
      applicationFeeData={applicationFeeData}
      categoryWiseVacancyDetail={categoryWiseVacancyDetail}
      vacancyDetails={vacancyDetails}
    />
  );
};

export default UpscExam;
