import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const BsphclRecruitment = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: '20 June 2024' },
        { rowElement: 'Application Deadline', rowElementresult: '19 July 2024' },
        { rowElement: 'Last Date to Pay Exam Fee', rowElementresult: '19 July 2024' },
        { rowElement: 'Exam Date', rowElementresult: 'As per Schedule' },
        { rowElement: 'Admit Card Available', rowElementresult: 'Before Exam' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age for Technician Grade III & JEE', rowElementresult: '18 Years' },
        { rowElement: 'Minimum Age for Other Posts', rowElementresult: '21 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '37 Years' },
        { rowElement: 'Age Relaxation', rowElementresult: 'As per BSPHCL Rules' },
    ];

    const vacancyDetails = [
        { rowElement: 'Technician Grade III', rowElementresult: '2000' },
        { rowElement: 'Junior Accounts Clerk', rowElementresult: '300' },
        { rowElement: 'Correspondence Clerk', rowElementresult: '150' },
        { rowElement: 'Store Assistant', rowElementresult: '80' },
        { rowElement: 'Junior Electrical Engineer (JEE) GTO', rowElementresult: '40' },
        { rowElement: 'Assistant Executive Engineer (GTO)', rowElementresult: '40' },
    ];

    const eligibilityData = [
        { rowElement: 'Technician Grade III', rowElementresult: 'Class 10th Passed with 2-Year ITI Certificate in Electrician Trade' },
        { rowElement: 'Junior Accounts Clerk', rowElementresult: 'Bachelor Degree in Commerce (B.Com) from Any Recognized University' },
        { rowElement: 'Correspondence Clerk', rowElementresult: 'Bachelor Degree in Any Stream from Any Recognized University' },
        { rowElement: 'Store Assistant', rowElementresult: 'Details as per official notification' },
        { rowElement: 'Junior Electrical Engineer (JEE) GTO', rowElementresult: 'Diploma in Electrical Engineering from Any Recognized Institute' },
        { rowElement: 'Assistant Executive Engineer (GTO)', rowElementresult: 'BE/B.Tech Degree in Electrical or Electrical & Electronics Engineering with Minimum 60% Marks (UR), 55% Marks (BC/EBC), 50% Marks (SC/ST)' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'Technician Grade III', ur: 500, ews: 200, sc: 400, st: 40, ebc: 500, bc: 360, total: 2000 },
        { postName: 'Junior Accounts Clerk', ur: 70, ews: 30, sc: 62, st: 7, ebc: 80, bc: 55, total: 300 },
        { postName: 'Correspondence Clerk', ur: 38, ews: 15, sc: 30, st: 3, ebc: 37, bc: 27, total: 150 },
        { postName: 'Store Assistant', ur: 20, ews: 8, sc: 16, st: 2, ebc: 20, bc: 14, total: 80 },
        { postName: 'Junior Electrical Engineer (JEE) GTO', ur: 0, ews: 4, sc: 12, st: 2, ebc: 12, bc: 10, total: 40 },
        { postName: 'Assistant Executive Engineer (GTO)', ur: 25, ews: 4, sc: 0, st: 2, ebc: 2, bc: 7, total: 40 },
    ];

    const applicationFeeData = [
        { rowElement: 'General / BC / EBC Fee', rowElementresult: '₹1500' },
        { rowElement: 'SC / ST Fee', rowElementresult: '₹375' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Debit Card, Credit Card, Net Banking' },
    ];

    const importantLinkData = [
        { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'https://www.bsphcl.bih.nic.in/apply-online' },
        { rowElement: 'Download Notification', rowElementresult: 'Click Here', resultLink: 'https://www.bsphcl.bih.nic.in/notification' },
        { rowElement: 'Download Date Change Notice', rowElementresult: 'Click Here', resultLink: 'https://www.bsphcl.bih.nic.in/date-change' },
        { rowElement: 'BSPHCL Official Website', rowElementresult: 'Click Here', resultLink: 'https://www.bsphcl.bih.nic.in' },
    ];

    return (
        <Createnewjob
            URL='https://www.bsphcl.bih.nic.in'
            mainHead={"Bihar BSPHCL Technical Grade III & Various Post Recruitment 2024"}
            head={"Bihar BSPHCL Technical Grade III & Various Post Recruitment 2024"}
            ageLimitData={ageLimitData}
            eligibilityData={eligibilityData}
            dateData={dateData}
            importantLinkData={importantLinkData}
            applicationFeeData={applicationFeeData}
            vacancyDetails={vacancyDetails}
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
};

export default BsphclRecruitment;
