import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const RailwayJuniorEngineer = () => {
    const dateData = [
        { rowElement: 'Application Begin', rowElementresult: '30 July 2024' },
        { rowElement: 'Last Date for Apply Online', rowElementresult: '29 August 2024' },
        { rowElement: 'Last Date Pay Exam Fee', rowElementresult: '29 August 2024' },
        { rowElement: 'Correction / Modified Form', rowElementresult: '30 August 2024 to 08 September 2024' },
        { rowElement: 'Exam Date', rowElementresult: 'As per Schedule' },
        { rowElement: 'Admit Card Available', rowElementresult: 'Notified Soon' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '18 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '36 Years' },
        { rowElement: 'Age Relaxation', rowElementresult: 'Extra as per RRB Rules' },
    ];

    const vacancyDetails = [
        { rowElement: 'Junior Engineer, Depot Material Superintendent, and Chemical & Metallurgical Assistant', rowElementresult: '7934' },
        { rowElement: 'Chemical Supervisor / Research and Metallurgical Supervisor / Research (RRB Gorakhpur Only)', rowElementresult: '17' },
    ];

    const eligibilityData = [
        { rowElement: 'Educational Qualification', rowElementresult: 'Degree/Diploma in Engineering from Any Recognized Institute in India' },
        { rowElement: 'For Post Wise Eligibility', rowElementresult: 'Check Detailed Notification' },
    ];

    const applicationFeeData = [
        { rowElement: 'General / OBC / EWS', rowElementresult: '₹500' },
        { rowElement: 'SC / ST / PH / Female', rowElementresult: '₹250' },
        { rowElement: 'Fee Refund after Stage I Exam for UR/OBC/EWS', rowElementresult: '₹400' },
        { rowElement: 'Fee Refund after Stage I Exam for SC/ST/PH/Female', rowElementresult: '₹250' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
    ];

    const importantLinkData = [
        { rowElement: 'Apply Online', rowElementresult: 'Apply Here', resultLink: 'https://rrbapplyonline.com' },
        { rowElement: 'Download Notification', rowElementresult: 'Download Here', resultLink: 'https://rrb.gov.in/notification' },
        { rowElement: 'Download Syllabus', rowElementresult: 'Download Syllabus', resultLink: 'https://rrb.gov.in/syllabus' },
        { rowElement: 'Download Exam Pattern', rowElementresult: 'Download Exam Pattern', resultLink: 'https://rrb.gov.in/exam-pattern' },
        { rowElement: 'Download Post Wise Eligibility', rowElementresult: 'Check Eligibility', resultLink: 'https://rrb.gov.in/eligibility' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'RRB Ahmedabad WR', ur: 149, obc: 107, ews: 49, sc: 53, st: 24 },
        { postName: 'RRB Ajmer NWR', ur: 268, obc: 109, ews: 64, sc: 61, st: 27 },
        { postName: 'RRB Bangalore SWR', ur: 174, obc: 89, ews: 43, sc: 58, st: 33 },
        { postName: 'RRB Bhopal WR / WCR', ur: 239, obc: 98, ews: 51, sc: 62, st: 35 },
        { postName: 'RRB Bhubaneswar ECOR', ur: 76, obc: 36, ews: 26, sc: 20, st: 17 },
        { postName: 'RRB Bilaspur CR / SECR', ur: 238, obc: 103, ews: 41, sc: 65, st: 25 },
        { postName: 'RRB Chandigarh NR', ur: 150, obc: 88, ews: 46, sc: 43, st: 29 },
        { postName: 'RRB Chennai SR', ur: 273, obc: 147, ews: 87, sc: 91, st: 54 },
        { postName: 'RRB Gorakhpur NER', ur: 108, obc: 55, ews: 25, sc: 46, st: 25 },
        { postName: 'RRB Guwahati NFR', ur: 93, obc: 57, ews: 23, sc: 37, st: 15 },
        { postName: 'RRB Jammu and Srinagar NR', ur: 125, obc: 52, ews: 35, sc: 23, st: 16 },
        { postName: 'RRB Kolkata ER / SER', ur: 320, obc: 114, ews: 64, sc: 96, st: 66 },
        { postName: 'RRB Malda ER / SER', ur: 74, obc: 41, ews: 19, sc: 19, st: 10 },
        { postName: 'RRB Mumbai SCR / WR / CR', ur: 596, obc: 346, ews: 143, sc: 203, st: 89 },
        { postName: 'RRB Muzaffarpur ECR', ur: 4, obc: 4, ews: 1, sc: 2, st: 0 },
        { postName: 'RRB Patna ECR', ur: 95, obc: 62, ews: 33, sc: 39, st: 18 },
        { postName: 'RRB Prayagraj NCR / NR', ur: 213, obc: 70, ews: 34, sc: 50, st: 37 },
        { postName: 'RRB Ranchi SER', ur: 70, obc: 46, ews: 18, sc: 20, st: 13 },
        { postName: 'RRB Secunderabad ECOR / SCR', ur: 248, obc: 130, ews: 63, sc: 104, st: 45 },
        { postName: 'RRB Siliguri NFR', ur: 17, obc: 4, ews: 1, sc: 5, st: 1 },
        { postName: 'RRB Thiruvanathapuram SR', ur: 45, obc: 32, ews: 16, sc: 18, st: 10 },
    ];

    return (
        <Createnewjob
         
            mainHead="RRB Junior Engineer (JE) Recruitment 2024"
            head="Railway Recruitment Board Junior Engineer JE (CEN 03/2024) Recruitment 2024"
            ageLimitData={ageLimitData}
            eligibilityData={eligibilityData}
            dateData={dateData}
            importantLinkData={importantLinkData}
            applicationFeeData={applicationFeeData}
            vacancyDetails={vacancyDetails}
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
};

export default RailwayJuniorEngineer;
