import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

const Polytechnic = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: '08 January 2024' },
        { rowElement: 'Last Date for Apply Online', rowElementresult: '10 May 2024' },
        { rowElement: 'Last Date Pay Exam Fee', rowElementresult: '10 May 2024' },
        { rowElement: 'Correction Date', rowElementresult: '11-12 May 2024' },
        { rowElement: 'New Exam Date', rowElementresult: '13-20 June 2024' },
        { rowElement: 'Admit Card Available', rowElementresult: '29 May 2024' },
        { rowElement: 'Result Declared', rowElementresult: 'As per Schedule' },
        { rowElement: 'Counseling Start', rowElementresult: '12 July 2024' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '14 Years' },
        { rowElement: 'Maximum Age', rowElementresult: 'NA' },
        { rowElement: 'Date of Birth for Admission', rowElementresult: 'Before 01/07/2010' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'POLYTECHNIC', ur: 0, sc: 0, st: 0, obc: 0, ews: 0 }
    ];

    const vacancyDetails = [
        { rowElement: 'Group A - Diploma in Engineering', rowElementresult: 'Class 10 Exam Passed with 35% Marks, PCM 50%' },
        { rowElement: 'Group B - Diploma in Agriculture Engineering', rowElementresult: 'Class 10 with Agriculture Subject, PCM & Agriculture 50%' },
        { rowElement: 'Group C - Fashion Designing & Garments Technology', rowElementresult: 'Class 10 Passed with 35% Marks' },
        { rowElement: 'Group D - Modern Officer Management and Secretarial Service', rowElementresult: 'Passed 10+2 with Hindi and English' },
        { rowElement: 'Group E - Diploma in Pharmacy', rowElementresult: '10+2 in Science Stream with 50%' },
        { rowElement: 'Group F - PG Diploma in Biotechnology', rowElementresult: 'B.Sc. in Biology, Chemistry or Biochemistry' },
        { rowElement: 'Group G - PG Diploma in Marketing and Sales Management', rowElementresult: 'Bachelor Degree in Any Stream' },
        { rowElement: 'Group H - Diploma in Hotel Management and Catering', rowElementresult: '10+2 with 50% Marks' },
        { rowElement: 'Group I - Diploma in Aircraft Maintenance Engineering', rowElementresult: '10+2 with PCM and 50% Marks' },
        { rowElement: 'Group J - PG Diploma in Information Technology', rowElementresult: 'Diploma in Engineering (Any Branch)' },
        { rowElement: 'Group K - Diploma in Engineering (Lateral Entry)', rowElementresult: '10+2 Science Stream or Class 10 with ITI Certificate' },
        { rowElement: 'Group L - Post Diploma in Industry Safety', rowElementresult: 'BE/B.Tech with 2 Years Experience OR Diploma with 5 Years Experience' },
    ];

    const eligibilityData = [
        { rowElement: 'Educational Qualification', rowElementresult: 'Varies by Group and Course (see details)' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
    ];

    const applicationFeeData = [
        { rowElement: 'General/OBC Fee', rowElementresult: '₹300' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹200' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online via Debit/Credit Card, Net Banking, Mobile Wallet, or Offline via E-Challan' },
    ];

    const importantLinkData = [
        { rowElement: 'Online Counseling', rowElementresult: 'Click Here', resultLink: 'url' },
        { rowElement: 'Download Counseling Schedule', rowElementresult: 'Click Here', resultLink: 'url' },
        { rowElement: 'Download Answer Key', rowElementresult: 'Click Here', resultLink: 'url' },
        { rowElement: 'Download Admit Card', rowElementresult: 'Click Here', resultLink: 'url' },
        { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'url' },
    ];

    return (
        <Createnewjob 
            URL='http://url' // Fixed URL formatting
            mainHead="Poletechnic Bihar"
            head="Poletechnic Bihar Examination 2024"
            ageLimitData={ageLimitData}
            eligibilityData={eligibilityData}
            dateData={dateData}  
            importantLinkData={importantLinkData}
            applicationFeeData={applicationFeeData}
            vacancyDetails={vacancyDetails}
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
}

export default Polytechnic; // Fixed the export name
