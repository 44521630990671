import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const BpscAssistantTeacher = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: '05 December 2022' },
        { rowElement: 'Application Deadline', rowElementresult: '23 December 2022' },
        { rowElement: 'Last Date for Pay Exam Fee', rowElementresult: '23 December 2022' },
        { rowElement: 'Correction Last Date', rowElementresult: '23 December 2022' },
        { rowElement: 'Exam Date', rowElementresult: 'As per Schedule' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '21 Years' },
        { rowElement: 'Maximum Age for Male', rowElementresult: '37 Years' },
        { rowElement: 'Maximum Age for Female', rowElementresult: '40 Years' },
        { rowElement: 'Age Relaxation', rowElementresult: 'As per BPSC rules' },
    ];

    const vacancyDetails = [
        { rowElement: 'Assistant Maulvi (Arabi)', rowElementresult: '02' },
        { rowElement: 'Assistant Teacher English', rowElementresult: '01' },
    ];
    const categoryWiseVacancyDetail = [
        { postName: 'BPSC TEACHER', ur: 'N/A', ews: 'N/A', sc: 'N/A',st: 'N/A', ebc: 'N/A', bc: 'N/A' },
       
    ];

    const eligibilityData = [
        { rowElement: 'Assistant Maulvi (Arabi)', rowElementresult: 'Master’s Degree in Related Subject with Minimum Second Division Marks. B.Ed / M.Ed / PhD' },
        { rowElement: 'Assistant Teacher English', rowElementresult: 'Master’s Degree in English with Minimum Second Division Marks. B.Ed / M.Ed / PhD' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
    ];

    const applicationFeeData = [
        { rowElement: 'General/OBC/Other State Fee', rowElementresult: '₹750' },
        { rowElement: 'SC/ST/PH Fee', rowElementresult: '₹200' },
        { rowElement: 'Application Fee for Females (Bihar Domicile)', rowElementresult: '₹200' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online / Offline Mode' },
    ];

    const importantLinkData = [
        { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'https://www.sarkariresult.com/apply-online' },
        { rowElement: 'Download Notification', rowElementresult: 'Click Here', resultLink: '' },
        { rowElement: 'BPSC Official Website', rowElementresult: 'Click Here', resultLink: 'https://www.bpsc.bih.nic.in' },
    ];

    return (
        <Createnewjob
            mainHead={"BPSC Assistant Teacher English & Assistant Mauvi Arabi Recruitment 2022"}
            head={"BPSC Assistant Teacher English & Assistant Maulvi (Arabi) Recruitment 2022"}
            ageLimitData={ageLimitData}
            eligibilityData={eligibilityData}
            dateData={dateData}
            importantLinkData={importantLinkData}
            applicationFeeData={applicationFeeData}
            vacancyDetails={vacancyDetails}
            categoryWiseVacancyDetail= {categoryWiseVacancyDetail}
        />
    );
};

export default BpscAssistantTeacher;
