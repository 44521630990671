import React from 'react'
import { Link } from 'react-router-dom'
export const ExploreLink = ({links,title}) => {
  return (
    
    <div className='w-[49vw] sm:w-[42vw] lg:w-[27vw] border h-full  md:mb-2 justify-center shadow-lg rounded-lg'>
    <h1 className='font-semibold text-lg sm:text-xl lg:text-2xl text-center p-2 bg-gradient-to-r from-sky-800 to-sky-400 text-white rounded'>{title}</h1>
    <ul>
    {links.map((link,index)=>(
      <Link to={link.url} key={index}>
      <li className=' w-[96%] sm:p-[8px] p-[6px] text-sm  shadow-sm m-1  text-center bg-white text-blue-700 text-md rounded-md hover:bg-slate-50 hover:shadow-md hover:text-red-500 '>
      {link.linkLabel}
      </li>
  </Link>
    
))}
   
    </ul>
    </div>
  )
}//suniyi deti hai jiski dardkan
export default ExploreLink