import React from 'react'

const CommingSoon = () => {
  return (
    <div className='bg-slate-100 h-[70vh] content-center'>
<p className='text-center text-4xl text-teal-700 font-semibold '>Comming Soon</p>
<p className='text-center text-slate-600'>We are working on it</p>
    </div>
  )
}
export default CommingSoon;
