import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

const CsirNet = () => {
    const dateData = [
        { rowElement: 'Application Begin', rowElementresult: '01 May 2024' },
        { rowElement: 'Last Date for Apply Online', rowElementresult: '27 May 2024' },
        { rowElement: 'Pay Exam Fee Last Date', rowElementresult: '27 May 2024' },
        { rowElement: 'Correction Date', rowElementresult: '29-31 May 2024' },
        { rowElement: 'Original Exam Date', rowElementresult: '25-27 June 2024 (Postponed)' },
        { rowElement: 'New Exam Date', rowElementresult: '25-27 July 2024' },
        { rowElement: 'Exam City Available', rowElementresult: '16 July 2024' },
        { rowElement: 'Admit Card Available', rowElementresult: '22 July 2024' },
        { rowElement: 'Answer Key Available', rowElementresult: '09 August 2024' },
        { rowElement: 'Result Declared', rowElementresult: '12 September 2024' },
    ];

    const ageLimitData = [
        { rowElement: 'Maximum Age for JRF', rowElementresult: '28 Years' },
        { rowElement: 'Maximum Age for Lectureship (LS) / Assistant Professor', rowElementresult: 'No Limit' },
    ];

    const eligibilityData = [
        { rowElement: 'Chemical Science', rowElementresult: 'M.Sc / Equivalent Degree with 55% Marks for General / EWS Candidates. For OBC / SC / ST and PH Candidates: 50% Marks.' },
        { rowElement: 'Earth, Atmospheric, Ocean and Planetary Science', rowElementresult: 'M.Sc / Equivalent Degree with 55% Marks for General / EWS Candidates. For OBC / SC / ST and PH Candidates: 50% Marks.' },
        { rowElement: 'Life Science', rowElementresult: 'M.Sc / Equivalent Degree with 55% Marks for General / EWS Candidates. For OBC / SC / ST and PH Candidates: 50% Marks.' },
        { rowElement: 'Mathematical Science', rowElementresult: 'M.Sc / Equivalent Degree with 55% Marks for General / EWS Candidates. For OBC / SC / ST and PH Candidates: 50% Marks.' },
        { rowElement: 'Physical Science', rowElementresult: 'M.Sc / Equivalent Degree with 55% Marks for General / EWS Candidates. For OBC / SC / ST and PH Candidates: 50% Marks.' },
    ];

    const applicationFeeData = [
        { rowElement: 'General / EWS Fee', rowElementresult: '₹1150' },
        { rowElement: 'OBC Fee', rowElementresult: '₹600' },
        { rowElement: 'SC / ST Fee', rowElementresult: '₹325' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Debit Card / Credit Card / Net Banking / UPI Only' },
    ];

    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://csirnet.nta.ac.in/images/CSIR_Information%20Bulletin_June%202024.pdf' },
        { rowElement: 'Apply Online', rowElementresult: 'Apply Now', resultLink: 'https://csirnet.ntaonline.in/frontend/web/' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://csirnet.ntaonline.in/frontend/web/admitcard/admit-card-july2024' },
        { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://csirnet.ntaonline.in/frontend/web/scorecard/index' },
    ];

    const vacancyDetails = [
        { rowElement: 'Total Vacancies', rowElementresult: '35000-60000' }, // Adjust as needed
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'CSIR UGC NET Chemical Science', ur: 100, sc: 200, st: 50, obc: 150, ews: 110 },
        { postName: 'CSIR UGC NET Earth Science', ur: 100, sc: 200, st: 50, obc: 150, ews: 110 },
        { postName: 'CSIR UGC NET Life Science', ur: 100, sc: 200, st: 50, obc: 150, ews: 110 },
        { postName: 'CSIR UGC NET Mathematical Science', ur: 100, sc: 200, st: 50, obc: 150, ews: 110 },
        { postName: 'CSIR UGC NET Physical Science', ur: 100, sc: 200, st: 50, obc: 150, ews: 110 },
    ];

    return (
        <Createnewjob 
            URL='http://example.com' // Replace with the actual URL
            mainHead={"CSIR UGC NET June 2024 Exam Details"}
            head={"CSIR UGC NET June 2024 Exam Information"}
            ageLimitData={ageLimitData}
            eligibilityData={eligibilityData}
            dateData={dateData}
            importantLinkData={importantLinkData}
            applicationFeeData={applicationFeeData}
            vacancyDetails={vacancyDetails} 
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
};

export default CsirNet;
