import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const NavalAcademy = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: 'As per schedule' },
        { rowElement: 'Application Deadline', rowElementresult: 'As per schedule' },
        { rowElement: 'Admit Card Release Date', rowElementresult: 'As per schedule' },
        { rowElement: 'Exam Date', rowElementresult: 'As per schedule' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '18 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '19 Years' },
    ];

    const vacancyDetails = [
        { rowElement: 'Naval Academy (NA) Exam', rowElementresult: 'NA' },
    ];

    const eligibilityData = [
        { rowElement: 'National Defence Academy NDA (Male / Female)', rowElementresult: '10+2 Intermediate Exam in Any Recognized Board' },
        { rowElement: 'Naval Academy NA Only for Male', rowElementresult: 'Class 10 (High School) Exam Passed from any recognized board in India.' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
        { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
    ];

    const applicationFeeData = [
        { rowElement: 'General/OBC Fee', rowElementresult: '₹100' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹00' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
    ];

    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://upsc.gov.in/examinations/National%20Defence%20Academy%20and%20Naval%20Academy%20Examination%20%28I%29%2C%202024' },
        { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://incet.cbt-exam.in/login/user' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://upsc.gov.in/examinations/National%20Defence%20Academy%20and%20Naval%20Academy%20Examination%20%28I%29%2C%202024' },
        { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://upsc.gov.in/examinations/National%20Defence%20Academy%20and%20Naval%20Academy%20Examination%20%28I%29%2C%202024' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'Indian Navy Entrance Test (INET)', ur: 0, sc: 0, st: 0, obc: 0, ews: 0 },
    ];

    return (
        <>
            <Createnewjob 
                URL='https://incet.cbt-exam.in/login/user'
                mainHead={"The Naval Academy (NA) Examination 2024"}
                head={"The Naval Academy (NA) Examination 2024"}
                ageLimitData={ageLimitData}
                eligibilityData={eligibilityData}
                dateData={dateData}
                importantLinkData={importantLinkData}
                applicationFeeData={applicationFeeData}
                vacancyDetails={vacancyDetails}
                categoryWiseVacancyDetail={categoryWiseVacancyDetail}
            />
        </>
    );
}

export default NavalAcademy;
