import React from 'react'
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const SscGd= () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: 'END' },
        { rowElement: 'Application Deadline', rowElementresult: 'END' },
        { rowElement: 'Admit Card Release Date', rowElementresult: '26 September 2024' },
        { rowElement: 'Exam Date', rowElementresult: '30 September 2024 to 14 November 2024' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '18 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '23 Years' },
        { rowElement: 'Maximum Age for certain posts', rowElementresult: 'Not Applicable' },
    ];

    const vacancyDetails = [
        { rowElement: 'SSC GD Constable Grade A', rowElementresult: '50,187' },
        { rowElement: 'SSC GD Constable Grade B', rowElementresult: '0' },
    ];

    const eligibilityData = [
        { rowElement: 'Educational Qualification', rowElementresult: 'Minimum 10th passed' },
        { rowElement: 'Experience Required', rowElementresult: 'No Experience Required' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
        { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
    ];

    const applicationFeeData = [
        { rowElement: 'General/OBC Fee', rowElementresult: '₹100' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹00' },
        { rowElement: 'Application Fee for Females', rowElementresult: '₹00' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
    ];

    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://ssc.nic.in/Portal/Notices' },
        { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://ssc.nic.in/Portal/Apply' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://ssc.nic.in/Portal/AdmitCard' },
        { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://ssc.nic.in/Portal/Results' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'SSC GD ', ur:22424 ,sc:8169, st:4445, obc:11057, ews:4092 },
    ];

    return (
        <>
            <Createnewjob
                URL=''
                mainHead={"SSC GD Constable (General Duty) Examination 2024 Result"}
                head={"SSC GD Constable (General Duty) Examination 2024 Result"}
                ageLimitData={ageLimitData}
                eligibilityData={eligibilityData}
                dateData={dateData}
                importantLinkData={importantLinkData}
                applicationFeeData={applicationFeeData}
                vacancyDetails={vacancyDetails}
                categoryWiseVacancyDetail={categoryWiseVacancyDetail}
            />
        </>
    )
}

export default SscGd;
