import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const UPTETExam = () => {
    const dateData = [
        { rowElement: 'Application Begin', rowElementresult: '01 October 2024' },
        { rowElement: 'Last Date for Registration', rowElementresult: '30 October 2024' },
        { rowElement: 'Pay Exam Fee Last Date', rowElementresult: '31 October 2024' },
        { rowElement: 'Last Date to Complete Form', rowElementresult: '01 November 2024' },
        { rowElement: 'Exam Date', rowElementresult: '15 December 2024' },
        { rowElement: 'Answer Key Available', rowElementresult: '20 December 2024' },
        { rowElement: 'Result Declared', rowElementresult: '15 February 2025 (Tentative)' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: 'No Minimum Age' },
        { rowElement: 'Maximum Age', rowElementresult: 'No Maximum Age' },
        { rowElement: 'Age Relaxation', rowElementresult: 'As per UPTET rules' },
    ];

    const vacancyDetails = [
        { rowElement: 'UPTET Primary Level', rowElementresult: 'Varies by Requirement' },
        { rowElement: 'UPTET Junior Level', rowElementresult: 'Varies by Requirement' },
    ];

    const eligibilityData = [
        { rowElement: 'PRIMARY LEVEL', rowElementresult: '' },
        { rowElement: '1. Bachelor Degree in Any Stream with Passed/Appeared DELED (Final Year) Exam', rowElementresult: 'Bachelor Degree in Any Stream with Passed/Appeared DELED (Final Year) Exam' },
        { rowElement: '2. Bachelor Degree in Any Stream with 50% Marks / Master Degree in Any Subject with B.Ed Degree (Passed/Appearing)', rowElementresult: 'Bachelor Degree in Any Stream with 50% Marks / Master Degree in Any Subject with B.Ed Degree (Passed/Appearing)' },
        { rowElement: '3. Bachelor Degree in Any Stream with 45% Marks / Master Degree in Any Subject with B.Ed Degree (Passed/Appearing) (As per NCTE Norms)', rowElementresult: 'Bachelor Degree in Any Stream with 45% Marks / Master Degree in Any Subject with B.Ed Degree (Passed/Appearing) (As per NCTE Norms)' },
        { rowElement: '4. 10+2 Intermediate 50% Marks with 4 Year B.ElEd', rowElementresult: '10+2 Intermediate 50% Marks with 4 Year B.ElEd' },
        { rowElement: '5. Bachelor Degree in Any Stream with BTC Urdu / Special Degree', rowElementresult: 'Bachelor Degree in Any Stream with BTC Urdu / Special Degree' },
        { rowElement: '6. More Details Read Notification', rowElementresult: 'More Details Read Notification' },

        { rowElement: 'JUNIOR LEVEL', rowElementresult: '' },
        { rowElement: '1. Bachelor Degree / Master Degree with 50% Marks with B.Ed / B.Ed Special Exam Passed / Appearing (Final Year)', rowElementresult: 'Bachelor Degree / Master Degree with 50% Marks with B.Ed / B.Ed Special Exam Passed / Appearing (Final Year)' },
        { rowElement: '2. Bachelor Degree / Master Degree with BTC Training 2 Year Exam Passed', rowElementresult: 'Bachelor Degree / Master Degree with BTC Training 2 Year Exam Passed' },
        { rowElement: '3. 10+2 Intermediate Exam with 50% Marks and B.LEd 4 Year Course', rowElementresult: '10+2 Intermediate Exam with 50% Marks and B.LEd 4 Year Course' },
        { rowElement: '4. 10+2 Inter Exam with 50% Marks and B.SC Ed Exam Passed', rowElementresult: '10+2 Inter Exam with 50% Marks and B.SC Ed Exam Passed' },
        { rowElement: '5. Bachelor Degree in Any Stream with 45% Marks / Master Degree in Any Subject with B.Ed Degree (Passed/Appearing) (As per NCTE Norms)', rowElementresult: 'Bachelor Degree in Any Stream with 45% Marks / Master Degree in Any Subject with B.Ed Degree (Passed/Appearing) (As per NCTE Norms)' },
        { rowElement: '6. More Details Read Notification', rowElementresult: 'More Details Read Notification' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'UP TET ', ur: 'N/A', sc: 'N/A', st: 'N/A', obc: 'N/A', ews: 'N/A' },
    ];

    const applicationFeeData = [
        { rowElement: 'For Paper I Only - General/OBC', rowElementresult: '₹600' },
        { rowElement: 'For Paper I Only - SC/ST', rowElementresult: '₹400' },
        { rowElement: 'For Paper I Only - PH (Divyang)', rowElementresult: '₹100' },
        { rowElement: 'For Both Paper (Junior/Primary) - General/OBC', rowElementresult: '₹1200' },
        { rowElement: 'For Both Paper (Junior/Primary) - SC/ST', rowElementresult: '₹800' },
        { rowElement: 'For Both Paper (Junior/Primary) - PH (Divyang)', rowElementresult: '₹200' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'SBI I Collect Fee Mode (Debit Card, Credit Card, Net Banking) or SBI E Challan Fee Mode' },
    ];

    const importantLinkData = [
        { rowElement: 'Download Result', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download Final Answer Key - Primary | Junior', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'For Online Objection', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download Answer Key - Primary | Junior', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download Answer Key Notice', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download Exam Notice', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download Date Extended Notice', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'For NIOS Candidate Notice', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'For Registration Number Problem & Solutions', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download UPTET Schedule', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download UPTET 2024 GO', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download UPTET 2024 Syllabus', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'Download UPTET 2024 Eligibility Details', rowElementresult: 'Click Here', resultLink: 'URL' },
        { rowElement: 'UPTET 2024 Official Website', rowElementresult: 'Click Here', resultLink: 'https://updeled.gov.in/' },
    ];

    return (
        <Createnewjob
            mainHead={"UPTET 2024 - Uttar Pradesh Teacher Eligibility Test"}
            head={"UPTET 2024 Notification and Details"}
            dateData={dateData}
            applicationFeeData={applicationFeeData}
            eligibilityData={eligibilityData}
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
            importantLinkData={importantLinkData}
            vacancyDetails={vacancyDetails}
            ageLimitData={ageLimitData}
        />
    );
}

export default UPTETExam;
