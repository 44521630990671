import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const RrbParamedical = () => {
  const dateData = [
    { rowElement: 'Application Begin', rowElementresult: '17 August 2024' },
    { rowElement: 'Last Date for Apply Online', rowElementresult: '16 September 2024' },
    { rowElement: 'Last Date to Pay Exam Fee', rowElementresult: '16 September 2024' },
    { rowElement: 'Correction / Modified Form', rowElementresult: 'As per Schedule' },
    { rowElement: 'Exam Date', rowElementresult: 'As per Schedule' },
    { rowElement: 'Admit Card Available', rowElementresult: 'Notified Soon' },
  ];

  const ageLimitData = [
    { rowElement: 'Minimum Age', rowElementresult: '18 Years' },
    { rowElement: 'Maximum Age for General Category', rowElementresult: '33-43 Years' },
    { rowElement: 'Age Relaxation', rowElementresult: 'Extra as per RRB Rules' },
  ];

  const applicationFeeData = [
    { rowElement: 'General / OBC / EWS', rowElementresult: '₹500' },
    { rowElement: 'SC / ST / PH', rowElementresult: '₹250' },
    { rowElement: 'All Category Female', rowElementresult: '₹250' },
    { rowElement: 'Fee Refund After Stage I Exam (UR/OBC/EWS)', rowElementresult: '₹400' },
    { rowElement: 'Fee Refund After Stage I Exam (SC / ST / PH / Female)', rowElementresult: '₹250' },
    { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only (Debit/Credit/Net Banking)' },
  ];

  const eligibilityData = [
    { rowElement: 'Educational Qualification', rowElementresult: 'Bachelor’s Degree in Relevant Field' },
    { rowElement: 'Experience Required', rowElementresult: 'Minimum 2 Years' },
    { rowElement: 'Nationality', rowElementresult: 'Indian' },
    { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
  ];

  const vacancyDetails = [
    { rowElement: 'Dietician (Level 7)', rowElementresult: '05' },
    { rowElement: 'Nursing Superintendent', rowElementresult: '713' },
    { rowElement: 'Audiologist & Speech Therapist', rowElementresult: '04' },
    { rowElement: 'Clinical Psychologist', rowElementresult: '07' },
    { rowElement: 'Dental Hygienist', rowElementresult: '03' },
    { rowElement: 'Dialysis Technician', rowElementresult: '20' },
    { rowElement: 'Health & Malaria Inspector Gr III', rowElementresult: '126' },
    { rowElement: 'Laboratory Superintendent', rowElementresult: '27' },
    { rowElement: 'Perfusionist', rowElementresult: '02' },
    { rowElement: 'Physiotherapist Grade II', rowElementresult: '20' },
    { rowElement: 'Occupational Therapist', rowElementresult: '02' },
    { rowElement: 'Cath Laboratory Technician', rowElementresult: '02' },
    { rowElement: 'Pharmacist (Entry Grade)', rowElementresult: '246' },
    { rowElement: 'Radiographer X-Ray Technician', rowElementresult: '64' },
    { rowElement: 'Speech Therapist', rowElementresult: '01' },
    { rowElement: 'Cardiac Technician', rowElementresult: '04' },
    { rowElement: 'Optometrist', rowElementresult: '04' },
    { rowElement: 'ECG Technician', rowElementresult: '13' },
    { rowElement: 'Laboratory Assistant Grade II', rowElementresult: '94' },
    { rowElement: 'Field Worker', rowElementresult: '19' },
  ];

  const categoryWiseVacancyDetail = [
    { postName: 'RRB Ahmedabad WR', ur: 32, obc: 10, ews: 3, sc: 4, st: 2, total: 51 },
    { postName: 'RRB Ajmer NWR', ur: 17, obc: 5, ews: 2, sc: 8, st: 2, total: 34 },
    { postName: 'RRB Bangalore SWR', ur: 39, obc: 15, ews: 5, sc: 15, st: 8, total: 82 },
    { postName: 'RRB Bhopal WR/WCR', ur: 25, obc: 4, ews: 2, sc: 7, st: 3, total: 41 },
    { postName: 'RRB Bhubaneswar ECOR', ur: 2, obc: 2, ews: 0, sc: 3, st: 1, total: 8 },
    { postName: 'RRB Bilaspur CR/SECR', ur: 23, obc: 11, ews: 5, sc: 6, st: 3, total: 48 },
    { postName: 'RRB Chandigarh NR', ur: 32, obc: 9, ews: 1, sc: 3, st: 3, total: 48 },
    { postName: 'RRB Chennai SR', ur: 65, obc: 16, ews: 22, sc: 23, st: 17, total: 143 },
    { postName: 'RRB Gorakhpur NER', ur: 36, obc: 19, ews: 7, sc: 13, st: 8, total: 83 },
    { postName: 'RRB Guwahati NFR', ur: 35, obc: 25, ews: 8, sc: 20, st: 7, total: 95 },
    { postName: 'RRB Jammu and Srinagar NR/RCF/DMW', ur: 13, obc: 1, ews: 1, sc: 3, st: 1, total: 19 },
    { postName: 'RRB Kolkata ER/SER/Metro', ur: 112, obc: 55, ews: 16, sc: 28, st: 18, total: 229 },
    { postName: 'RRB Malda ER/SER', ur: 19, obc: 5, ews: 2, sc: 6, st: 0, total: 32 },
    { postName: 'RRB Mumbai SCR/WR/CR', ur: 119, obc: 51, ews: 15, sc: 34, st: 17, total: 236 },
    { postName: 'RRB Muzaffarpur ECR', ur: 10, obc: 1, ews: 0, sc: 2, st: 3, total: 16 },
    { postName: 'RRB Patna ECR', ur: 14, obc: 9, ews: 3, sc: 6, st: 3, total: 35 },
    { postName: 'RRB Prayagraj NCR/NR/DLW', ur: 27, obc: 3, ews: 7, sc: 7, st: 2, total: 41 },
    { postName: 'RRB Ranchi SER/ECR', ur: 12, obc: 8, ews: 2, sc: 6, st: 2, total: 30 },
    { postName: 'RRB Secunderabad ECOR/SCR', ur: 46, obc: 15, ews: 7, sc: 4, st: 7, total: 79 },
    { postName: 'RRB Siliguri NFR', ur: 13, obc: 5, ews: 2, sc: 4, st: 2, total: 26 },
  ];

  const importantLinkData = [
    { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'https://www.rrbapply.gov.in/#/auth/landing' },
    { rowElement: 'Download Notification', rowElementresult: 'Click Here', resultLink: 'https://www.example.com/notification' },
    { rowElement: 'Download Post Wise Eligibility', rowElementresult: 'Click Here', resultLink: 'https://www.example.com/post-wise-eligibility' },
    { rowElement: 'Download RRB Wise Vacancy', rowElementresult: 'Click Here', resultLink: 'https://www.example.com/rrb-wise-vacancy' },
  ];

  return (
    <Createnewjob
      URL="https://www.rrbapply.gov.in"
      mainHead="Railway Recruitment Board (RRB) Paramedical Categories Recruitment 2024"
      head="RRB Railway Paramedical Categories Recruitment 2024 (CEN 04/2024)"
      dateData={dateData}
      ageLimitData={ageLimitData}
      applicationFeeData={applicationFeeData}
      importantLinkData={importantLinkData}
      vacancyDetails={vacancyDetails}
      eligibilityData={eligibilityData}
      categoryWiseVacancyDetail={categoryWiseVacancyDetail}
    />
  );
};

export default RrbParamedical;
