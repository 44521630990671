import React, { useState } from 'react';
import Fuse from 'fuse.js';
import { Link, useNavigate} from 'react-router-dom';
import { RxCross1 } from "react-icons/rx";
import LatestJobLinks from './../home/home-components/explore/Links/LastestJob'
import AdmitCardLinks from './../home/home-components/explore/Links/AdmitCard'
import ResultLinks from './../home/home-components/explore/Links/Result'
import SyllabusLinks from './../home/home-components/explore/Links/Syllabus'
import AnswerKeyLinks from './../home/home-components/explore/Links/AnswerKey'
import AdmissionLinks from './../home/home-components/explore/Links/Admission'
const combinedLink = [
     ...LatestJobLinks,
     ...AdmitCardLinks,
     ...ResultLinks,
     ...SyllabusLinks,
     ...AnswerKeyLinks,
     ...AdmissionLinks
]
  

export const Base = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState(combinedLink);

  // Fuse.js configuration
  const options = {
    keys: ["url", "linkLabel"], // Corrected keys for search
    threshold: 0.3, // Lower value means stricter matching
  };

  const fuse = new Fuse(combinedLink, options);

  // Handle search input
  const handleSearch = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value) {
      const fuseResults = fuse.search(value);
      setResults(fuseResults.map(result => result.item));
    } else {
      setResults(combinedLink); // Reset to full list if search is cleared
    }
  };


  const navigate = useNavigate(); // Get the navigate function

  const handleBackClick = () => {
    navigate(-1); // Go back one step in history
  };

  return (
    <>
    <p className='p-4 pb-0 flex justify-end text-3xl text-red-900 bg-slate-50'><RxCross1 onClick={handleBackClick} /></p>
    <div className="container mx-auto p-4 pt-0 bg-slate-50">
      <div className="mb-4 bg-green-50 p-2 lg:pl-8 lg:pr-12 lg:p-4 shadow-lg lg:ml-12 lg:mr-10">
        <input
          type="text"
          placeholder="Search..."
          value={query}
          onChange={handleSearch}
          className="w-full p-1  pl-4 border border-gray-500 rounded focus:outline-none focus:border-blue-500 transition duration 200"
        />
      </div>

      {/* Display search results */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 m-2 lg:w-[60%]">
        {results.length > 0 ? (
          results.map((item, index) => (
            <div
              key={index}
              className="p-2 border border-gray 200 rounded-lg shadow-md hover:shadow-lg transition duration 200 text-center"
            >
              <Link
                to={item.url}
                className="text-sm lg:text-[16px] hover:text-blue-700 font-semibold text-blue-500" 
                
              >
                {item.linkLabel}
              </Link>
            </div>
          ))
        ) : (
          <div className="text-center text-red-600 text-lg lg:text-xl col-span-full p-4 bg-red-50 border border-red 200 rounded-lg">
            <p className="font-semibold">No results found</p>
            <p className="mt-2 text-gray-700">Try a different search term or check back later.</p>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default Base;
