import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const RrbNTPC = () => {
  const dateData = [
    { rowElement: 'Application Start On', rowElementresult: '14 September 2024' },
    { rowElement: 'Application Deadline', rowElementresult: '13 October 2024' },
    { rowElement: 'Last Date to Pay Exam Fee', rowElementresult: '13 October 2024' },
    { rowElement: 'Admit Card Release Date', rowElementresult: 'Notified Soon' },
    { rowElement: 'Exam Date', rowElementresult: 'As per Schedule' },
  ];

  const ageLimitData = [
    { rowElement: 'Minimum Age', rowElementresult: '18 Years' },
    { rowElement: 'Maximum Age', rowElementresult: '36 Years' },
    { rowElement: 'Age Relaxation', rowElementresult: 'As per RRB Vacancy Rules' },
  ];

  const vacancyDetails = [
    { rowElement: 'Chief Commercial Cum Ticket Supervisor', rowElementresult: '1736' },
    { rowElement: 'Station Master', rowElementresult: '994' },
    { rowElement: 'Good Train Manager', rowElementresult: '3144' },
    { rowElement: 'Junior Account Assistant Cum Typist', rowElementresult: '1507' },
    { rowElement: 'Senior Clerk Cum Typist', rowElementresult: '732' },
  ];

  const eligibilityData = [
    { rowElement: 'Chief Commercial Cum Ticket Supervisor', rowElementresult: 'Bachelor Degree in Any Stream from Any Recognized University in India' },
    { rowElement: 'Station Master', rowElementresult: 'Bachelor Degree in Any Stream from Any Recognized University in India' },
    { rowElement: 'Good Train Manager', rowElementresult: 'Bachelor Degree in Any Stream from Any Recognized University in India' },
    { rowElement: 'Junior Account Assistant Cum Typist', rowElementresult: 'Bachelor Degree in Any Stream from Any Recognized University in India' },
    { rowElement: 'Senior Clerk Cum Typist', rowElementresult: 'Bachelor Degree in Any Stream from Any Recognized University in India' },
  ];

  const categoryWiseVacancyDetail = [
    { postName: 'Chief Commercial Cum Ticket Supervisor', ur: 100, sc: 200, st: 500, obc: 150, ews: 110 },
    { postName: 'Station Master', ur: 120, sc: 220, st: 520, obc: 160, ews: 120 },
    // Add other categories as needed
  ];

  const applicationFeeData = [
    { rowElement: 'General/OBC Fee', rowElementresult: '₹500' },
    { rowElement: 'SC/ST Fee', rowElementresult: '₹250' },
    { rowElement: 'Application Fee for Females', rowElementresult: '₹250' },
    { rowElement: 'Fee Refund for UR/OBC/EWS after Exam', rowElementresult: '₹400' },
    { rowElement: 'Fee Refund for SC/ST/PH/Female after Exam', rowElementresult: '₹250' },
    { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
  ];

  const importantLinkData = [
    { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://www.rrbapply.gov.in/#/auth/landing' },
    { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://www.rrbapply.gov.in/#/auth/landing' },
    { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://www.rrbapply.gov.in/#/auth/landing' },
    { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://www.rrbapply.gov.in/#/auth/landing' },
  ];

  return (
    <Createnewjob
      URL="http://url"
      mainHead="Railway NTPC Graduate Level Post Recruitment 2024"
      head="RRB NTPC Graduate Level CEN 05/2024"
      ageLimitData={ageLimitData}
      eligibilityData={eligibilityData}
      dateData={dateData}
      importantLinkData={importantLinkData}
      applicationFeeData={applicationFeeData}
      categoryWiseVacancyDetail={categoryWiseVacancyDetail}
      vacancyDetails={vacancyDetails}
    />
  );
};

export default RrbNTPC;
