export const sscJobLinks = {
  sscCgl: { url: '/job/ssc-cgl', linkLabel: 'SSC-CGL 2024 Latest Job Information' },
  sscChsl: { url: '/job/ssc-chsl', linkLabel: 'SSC-CHSL 2024 Latest Job Information' },
  sscGd: { url: '/job/ssc-gd', linkLabel: 'SSC-GD 2024 Latest Job Information' },
  sscStenographer: { url: '/job/ssc-stenographer', linkLabel: 'SSC-Stenographer 2024 Latest Job Information' },
  sscMts: { url: '/job/ssc-mts', linkLabel: 'SSC-MTS 2024 Latest Job Information' },

}
  
 
  