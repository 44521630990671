import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const BtscHealthRecruitment = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: '02 August 2022' },
        { rowElement: 'Application Deadline', rowElementresult: '01 September 2022' },
        { rowElement: 'Female Health Worker ANM Last Date', rowElementresult: '06 October 2023' },
        { rowElement: 'ANM Exam Date', rowElementresult: '05, 11-12 January 2024' },
        { rowElement: 'Admit Card Available', rowElementresult: '30 December 2024' },
        { rowElement: 'ANM Result Available', rowElementresult: '12 February 2024' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '18-21 Years (Post Wise)' },
        { rowElement: 'Maximum Age (Male)', rowElementresult: '37 Years' },
        { rowElement: 'Maximum Age (Female)', rowElementresult: '40 Years' },
        { rowElement: 'Age Relaxation', rowElementresult: 'As per BTSC Recruitment Rules' },
    ];

    const vacancyDetails = [
        { rowElement: 'E.C.G Technician', rowElementresult: '163' },
        { rowElement: 'X-Ray Technician', rowElementresult: '803' },
        { rowElement: 'Operation Theater-Assistant (OTA)', rowElementresult: '1096' },
        { rowElement: 'Bihar Female Health Worker (A.N.M)', rowElementresult: '10709' },
    ];

    const eligibilityData = [
        { rowElement: 'E.C.G Technician', rowElementresult: 'Bachelor Degree OR Diploma in E.C.G Technician' },
        { rowElement: 'X-Ray Technician', rowElementresult: 'Diploma in X-Ray Technician / Bachelor in Radio Imaging Technology OR Higher Qualification' },
        { rowElement: 'Operation Theater Assistant (OTA)', rowElementresult: 'Diploma in Operation Theatre Assistant / Bachelor of Medical Laboratory Technology OR Higher Qualification' },
        { rowElement: 'Bihar Female Health Worker (A.N.M)', rowElementresult: 'Auxiliary Nurse Midwifery (ANM) OR Higher Qualification (GNM / B.Sc Nursing / M.Sc Nursing)' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'E.C.G Technician', ur: 50, sc: 30, st: 10, obc: 45, ews: 28 },
        { postName: 'X-Ray Technician', ur: 300, sc: 150, st: 50, obc: 200, ews: 103 },
        { postName: 'Operation Theater-Assistant (OTA)', ur: 400, sc: 250, st: 100, obc: 246, ews: 100 },
        { postName: 'Bihar Female Health Worker (A.N.M)', ur: 4500, sc: 2500, st: 1000, obc: 2709, ews: 1000 },
    ];

    const applicationFeeData = [
        { rowElement: 'General / OBC / EWS Fee', rowElementresult: '₹200' },
        { rowElement: 'SC / ST / PH Fee', rowElementresult: '₹50' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Debit Card / Credit Card / Net Banking' },
    ];

    const importantLinkData = [
        { rowElement: 'Download Result / Score Card', rowElementresult: 'Click Here', resultLink: 'https://www.btsc.bih.nic.in/result' },
        { rowElement: 'Download Result Notice', rowElementresult: 'Click Here', resultLink: 'https://www.btsc.bih.nic.in/result-notice' },
        { rowElement: 'Download Admit Card', rowElementresult: 'Click Here', resultLink: 'https://www.btsc.bih.nic.in/admit-card' },
        { rowElement: 'Download Exam Notice', rowElementresult: 'Click Here', resultLink: 'https://www.btsc.bih.nic.in/exam-notice' },
        { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'https://www.btsc.bih.nic.in/apply-online' },
        { rowElement: 'Download Female Health Worker ANM New Notification', rowElementresult: 'Click Here', resultLink: 'https://www.btsc.bih.nic.in/new-notification' },
        { rowElement: 'BTSC Official Website', rowElementresult: 'Click Here', resultLink: 'https://www.btsc.bih.nic.in' },
    ];

    return (
        <Createnewjob
            URL='https://www.btsc.bih.nic.in'
            mainHead={"BTSC Various Health Department Recruitment 2024(ANM)"}
            head={"Bihar Technical Service Commission (BTSC) Various Health Department Recruitment 2024 (ANM)"}
            ageLimitData={ageLimitData}
            eligibilityData={eligibilityData}
            dateData={dateData}
            importantLinkData={importantLinkData}
            applicationFeeData={applicationFeeData}
            vacancyDetails={vacancyDetails}
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
};

export default BtscHealthRecruitment;
