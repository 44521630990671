import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const SscStenographer = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: 'END' },
        { rowElement: 'Application Deadline', rowElementresult: 'END' },
        { rowElement: 'Admit Card Release Date', rowElementresult: '26 September 2024' },
        { rowElement: 'Exam Date', rowElementresult: '30 September 2024 to 14 November 2024' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '18 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '23 Years' },
        { rowElement: 'Maximum Age for certain posts', rowElementresult: '00 Years' },
    ];

    const vacancyDetails = [
        { rowElement: 'SSC Grade C', rowElementresult: '50 to 150' },
        { rowElement: 'SSC Grade D', rowElementresult: '800 to 1200' },
    ];

    const eligibilityData = [
        { rowElement: 'Educational Qualification', rowElementresult: 'Minimum 10th and 12th passed with stenography skill: 100 WPM for Grade C and 80 WPM for Grade D' },
        { rowElement: 'Experience Required', rowElementresult: 'No Experience Required' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
        { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
    ];

    const applicationFeeData = [
        { rowElement: 'General/OBC Fee', rowElementresult: '₹100' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹00' },
        { rowElement: 'Application Fee for Females', rowElementresult: '₹00' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
    ];

    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://ssc.nic.in/Portal/Notices' },
        { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://ssc.nic.in/Portal/Apply' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://ssc.nic.in/Portal/AdmitCard' },
        { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://ssc.nic.in/Portal/Results' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'SSC-GD Stenographer Grade C', ur: 49, sc: 13, st: 3, obc: 22, ews: 6 },
        { postName: 'SSC-GD Stenographer Grade D', ur: 499, sc: 165, st: 88, obc: 272, ews: 90 },
    ];

    return (
        <Createnewjob 
            URL='HTTP:// URL'
            mainHead={"SSC Stenographer Examination 2024 Result"}
            head={"SSC Stenographer Examination 2024 Result"}
            ageLimitData={ageLimitData}
            eligibilityData={eligibilityData}
            dateData={dateData}
            importantLinkData={importantLinkData}
            applicationFeeData={applicationFeeData}
            vacancyDetails={vacancyDetails}
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
}

export default SscStenographer;
