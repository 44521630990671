import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const Sscchsl = () => {
    // Date Data for SSC CHSL 2024
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: '08 April 2024' },
        { rowElement: 'Application Deadline', rowElementresult: '07 May 2024 (upto 11 PM)' },
        { rowElement: 'Online Payment Last Date', rowElementresult: '08 May 2024' },
        { rowElement: 'Correction Date', rowElementresult: '10-11 May 2024' },
        { rowElement: 'Online Exam Date Paper I', rowElementresult: '01-11 July 2024' },
        { rowElement: 'Answer Key Available Paper I', rowElementresult: '18 July 2024' },
        { rowElement: 'Result Available Paper I', rowElementresult: '06 September 2024' },
        { rowElement: 'Paper II Exam Date', rowElementresult: 'As per Schedule' },
    ];

    // Age Limit Data for SSC CHSL 2024
    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '18 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '27 Years as on 01/08/2024' },
        { rowElement: 'Age Relaxation', rowElementresult: 'As per SSC CHSL 2024 Rules' },
    ];

    // Vacancy Details for SSC CHSL 2024
    const vacancyDetails = [
        { rowElement: 'Total Vacancy', rowElementresult: '3712 Posts' },
    ];

    // Eligibility Criteria for SSC CHSL 2024
    const eligibilityData = [
        { rowElement: 'Lower Division Clerk (LDC) / Junior Secretariat Assistant (JSA)', rowElementresult: '10+2 Intermediate Exam in Any Recognized Board in India' },
        { rowElement: 'Postal Assistant (PA) / Sorting Assistant', rowElementresult: '10+2 Intermediate Exam in Any Recognized Board in India' },
        { rowElement: 'Data Entry Operators (DEOs)', rowElementresult: '10+2 Intermediate Exam in Any Recognized Board in India' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
    ];

    // Application Fee for SSC CHSL 2024
    const applicationFeeData = [
        { rowElement: 'General / OBC / EWS', rowElementresult: '₹100' },
        { rowElement: 'SC / ST / PH', rowElementresult: '₹0 (Nil)' },
        { rowElement: 'Female (All Category)', rowElementresult: '₹0 (Exempted)' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only (Debit Card, Credit Card, Net Banking)' },
    ];

    // Important Links for SSC CHSL 2024
    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://ssc.nic.in/SSC_CHSL_Notification_2024.pdf' },
        { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://ssc.nic.in/SSC_CHSL_Apply_2024' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://ssc.nic.in/SSC_CHSL_AdmitCard_2024' },
        { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://ssc.nic.in/SSC_CHSL_Results_2024' },
        { rowElement: 'Answer Key', rowElementresult: 'Download Here', resultLink: 'https://ssc.nic.in/SSC_CHSL_AnswerKey_2024' },
    ];

    // Category-wise Vacancy Detail for SSC CHSL 2024
    const categoryWiseVacancyDetail = [
        { postName: 'Lower Division Clerk (LDC)', ur: 1000, sc: 500, st: 200, obc: 700, ews: 300 },
        { postName: 'Junior Secretariat Assistant (JSA)', ur: 800, sc: 400, st: 150, obc: 500, ews: 200 },
        { postName: 'Postal Assistant (PA) / Sorting Assistant', ur: 900, sc: 450, st: 180, obc: 600, ews: 250 },
        { postName: 'Data Entry Operators (DEOs)', ur: 500, sc: 300, st: 100, obc: 400, ews: 150 },
    ];

    return (
        <>
            <Createnewjob 
                URL='https://ssc.nic.in/SSC_CHSL_Apply_2024'
                mainHead={"SSC Combined Higher Secondary Level (CHSL) 10+2 Examination 2024"}
                head={"SSC CHSL 10+2 Exam 2024 : Short Details of Notification"}
                ageLimitData={ageLimitData}
                eligibilityData={eligibilityData}
                dateData={dateData}
                importantLinkData={importantLinkData}
                applicationFeeData={applicationFeeData}
                vacancyDetails={vacancyDetails}
                categoryWiseVacancyDetail={categoryWiseVacancyDetail}
            />
        </>
    );
}

export default Sscchsl;