import React from 'react'
import { FaFacebook, FaYoutube, FaInstagram } from "react-icons/fa";
import {FaXTwitter} from "react-icons/fa6";

const ImportantLinks = ({importantLinkData}) => {
  return (
    <>
    <div className='p-2 md:p-4 shadow-xl mt-1'>
        <h2 className='font-semibold text-xl sm:text-2xl text-center p-2 bg-gradient-to-br from-green-900 to-green-400 text-white'>
          Important Links
        </h2>
        <ol className='list-none'>
        {importantLinkData.map((data, index) => (
          <li key={index} className='flex justify-between text-sm lg:text-lg   border border-b-red-900 p-2 pl-4 bg-slate-50 pr-2 text-red-900'>
          {data.rowElement} <p className='min-w-[45%] max-w-[45%] border-l-2 border-slate-600 pl-4 font-semibold text-blue-700 lg:text-xl hover:text-blue-800 hover:underline' ><a rel="noopener noreferrer" href={data.resultLink}>{ data.rowElementresult}</a></p>
        </li>
        ))}
        <div className='flex justify-center'>
        <ul className='flex text-3xl gap-5 justify-center p-[10px]  mt-4 mb-2 bg-green-50 border-2 border-slate-500 shadow-lg rounded-lg  items-center'>
        <li className='border-r-2 pr-6 border-slate-500'>
              <a rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=61564607174252&mibextid=ZbWKwL" aria-label="Facebook" target='blank' className='text-blue-500 cursor-pointer hover:text-blue-600'>
                <FaFacebook />
              </a>
              </li>
              <li className='border-r-2 pr-6 border-slate-500'>
              <a rel="noopener noreferrer" href="https://www.youtube.com/@sarkarilock1" aria-label="YouTube" target='blank' className='text-red-500 cursor-pointer hover:text-red-600'>
                <FaYoutube />
              </a>
              </li>
              <li className='border-r-2 pr-6 border-slate-500'>
              <a rel="noopener noreferrer" href="https://www.twitter.com" aria-label="Twitter" target='blank' className='text-black cursor-pointer hover:text-slate-700'>
                <FaXTwitter />
              </a>
              </li>
              <li>
              <a rel="noopener noreferrer" href="https://www.instagram.com/sarkarilock?igsh=MW1hNDV6cW11ZjBia==" target='blank' aria-label="Twitter" className='text-pink-500 cursor-pointer hover:text-pink-600'>
                <FaInstagram />
              </a>
              </li>
        </ul>
        </div>
        </ol>
      </div></>
  )
}
export default ImportantLinks;