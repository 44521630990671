import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const IndianCoastGuard = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: 'As per schedule' },
        { rowElement: 'Application Deadline', rowElementresult: 'As per schedule' },
        { rowElement: 'Admit Card Release Date', rowElementresult: 'As per schedule' },
        { rowElement: 'Exam Date', rowElementresult: 'As per schedule' },
    ];

    // Age Limit Data
    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '21 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '25 Years' },
    ];

    // Vacancy Details
    const vacancyDetails = [
        { rowElement: 'Indian Coast Guard (ICG) Assistant Commandant', rowElementresult: '250-300' },
    ];

    // Eligibility Criteria
    const eligibilityData = [
        { rowElement: 'General Duty (male)', rowElementresult: 'Bachelor Degree with Minimum 60% Marks in All Semesters / Years, with Maths and Physics as Subjects in 10+2 Level Examination' },
        { rowElement: 'Technical Mechanical (male)', rowElementresult: 'BE / B.Tech Engineering Degree in Naval Architecture, Mechanical, Marine, Automotive, Mechatronics, Industrial and Production, Metallurgy, Design, Aeronautical, or Aerospace with Minimum 60% Marks' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
        { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
    ];

    // Application Fee
    const applicationFeeData = [
        { rowElement: 'General/OBC Fee', rowElementresult: '₹300' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹00' },
        { rowElement: 'Application Fee for Females', rowElementresult: '₹00' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
    ];

    // Important Links
    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://www.sarkariresults.org.in/wp-content/uploads/2024/02/coastguard_ac_2025_batch_Sarkariresult_Com_w.pdf' },
        { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://joinindiancoastguard.cdac.in/cgcatreg/candidate/login' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://joinindiancoastguard.cdac.in/cgcatreg/candidate/login' },
        { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://joinindiancoastguard.cdac.in/cgcatreg/candidate/login' },
    ];

    // Category-wise Vacancy Detail
    const categoryWiseVacancyDetail = [
        { postName: 'Indian Coast Guard Assistant Commandant', ur: 0, sc: 0, st: 0, obc: 0, ews: 0 },
    ];

    return (
        <>
            <Createnewjob 
                URL='https://joinindiancoastguard.cdac.in/'
                mainHead={"Indian Coast Guard Assistant Commandant Examination 2024"}
                head={"Indian Coast Guard Assistant Commandant Examination 2024"}
                ageLimitData={ageLimitData}
                eligibilityData={eligibilityData}
                dateData={dateData}
                importantLinkData={importantLinkData}
                applicationFeeData={applicationFeeData}
                vacancyDetails={vacancyDetails}
                categoryWiseVacancyDetail={categoryWiseVacancyDetail}
            />
        </>
    );
}

export default IndianCoastGuard;
