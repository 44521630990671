import React from 'react';
import Navlink from './Navlink';

const HamMenu = ({ closeMenu }) => {
  console.log(closeMenu)
  return (
    <nav>
      <h1 className='rounded text-center text-white font-semibold text-xl border-2 p-2'>Navigation</h1>
      <ul className='list-none h-[100vh]'>
        <Navlink closeMenu={closeMenu} path="/">Home</Navlink>
        <Navlink closeMenu={closeMenu} path="/contact-us">Contact</Navlink>
        {/* <Navlink closeMenu={closeMenu} path="/latest-job">Latest Job</Navlink>
        <Navlink closeMenu={closeMenu} path="/result">Result</Navlink> */}
        {/* <Navlink closeMenu={closeMenu} path="/sarkari-yojana">Sarkari Yojana</Navlink> */}
        <Navlink closeMenu={closeMenu} path="/search-exam">Search exam</Navlink>
        {/* <Navlink closeMenu={closeMenu} path="/new-article">New Article</Navlink> */}
        <Navlink closeMenu={closeMenu} path="/about-us">About Us</Navlink>
        <Navlink closeMenu={closeMenu} path="/privacy-policy">Privacy & policy</Navlink>

      </ul>
    </nav>
  );
};

export default HamMenu;
