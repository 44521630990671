import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const IndianTerritorialArmyForce = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: 'As per schedule' },
        { rowElement: 'Application Deadline', rowElementresult: 'As per schedule' },
        { rowElement: 'Admit Card Release Date', rowElementresult: 'As per schedule' },
        { rowElement: 'Exam Date', rowElementresult: 'As per schedule' },
    ];

    // Age Limit Data
    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '18 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '42 Years' },
    ];

    // Vacancy Details
    const vacancyDetails = [
        { rowElement: 'Territorial Army (TA) Officer', rowElementresult: 'As per information' },
    ];

    // Eligibility Criteria
    const eligibilityData = [
        { rowElement: 'Educational Qualification', rowElementresult: 
          'A Bachelor\'s degree from a recognized university. No specific subject requirement; the degree can be in any discipline.' },
        { rowElement: 'Experience Required', rowElementresult: 'No Experience' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
        { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
    ];

    // Application Fee Data
    const applicationFeeData = [
        { rowElement: 'General/OBC Fee', rowElementresult: '₹200' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹00' },
        { rowElement: 'Application Fee for Females', rowElementresult: '₹00' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
    ];

    // Important Links
    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://www.jointerritorialarmy.gov.in/#main-content' },
        { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://cdn.digialm.com//EForms/configuredHtml/1258/85940/Index.html' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://cdn.digialm.com//EForms/configuredHtml/1258/85940/Index.html' },
        { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://cdn.digialm.com//EForms/configuredHtml/1258/85940/Index.html' },
    ];

    // Category-wise Vacancy Detail
    const categoryWiseVacancyDetail = [
        { postName: 'Territorial Army (TA) Officer', ur: 0, sc: 0, st: 0, obc: 0, ews: 0 },
    ];

    return (
        <>
            <Createnewjob 
                URL='https://www.jointerritorialarmy.gov.in/'
                mainHead={"Territorial Army (TA) Officer Exam 2024"}
                head={"Territorial Army (TA) Officer Exam 2024"}
                ageLimitData={ageLimitData}
                eligibilityData={eligibilityData}
                dateData={dateData}
                importantLinkData={importantLinkData}
                applicationFeeData={applicationFeeData}
                vacancyDetails={vacancyDetails}
                categoryWiseVacancyDetail={categoryWiseVacancyDetail}
            />
        </>
    );
}

export default IndianTerritorialArmyForce;
