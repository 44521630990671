import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const BiharPoliceSI = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: '05 October 2023' },
        { rowElement: 'Application Deadline', rowElementresult: '05 November 2023' },
        { rowElement: 'Re Upload Photo/Signature', rowElementresult: '09-14 November 2023' },
        { rowElement: 'Exam Date', rowElementresult: '17 December 2023' },
        { rowElement: 'Result Available', rowElementresult: '25 January 2024' },
        { rowElement: 'Phase II Mains Exam Date', rowElementresult: '25 February 2024' },
        { rowElement: 'Admit Card Available', rowElementresult: '06 February 2024' },
        { rowElement: 'Phase II Result Available', rowElementresult: '15 March 2024' },
        { rowElement: 'PET Admit Card Available', rowElementresult: '28 May 2024' },
        { rowElement: 'Final Result Available', rowElementresult: '09 July 2024' },
        { rowElement: 'Marksheet Available', rowElementresult: '19 August 2024' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '20 Years' },
        { rowElement: 'Maximum Age for Male', rowElementresult: '37 Years' },
        { rowElement: 'Maximum Age for Female', rowElementresult: '40 Years' },
        { rowElement: 'Age Relaxation', rowElementresult: 'As per BPSSC rules' },
    ];

    const vacancyDetails = [
        { rowElement: 'Sub Inspector (SI)', rowElementresult: '1275' },
    ];

    const eligibilityData = [
        { rowElement: 'Educational Qualification', rowElementresult: 'Bachelor Degree in Any Recognized University in India' },
        { rowElement: 'More Eligibility Details', rowElementresult: 'Read the Notification for More Details' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'Sub Inspector (SI)', ur: 441, ews: 111, ebc: 238, obc: 107, bcFemale: 82, sc: 275, st: 16, transgender: 5 },
    ];

    const applicationFeeData = [
        { rowElement: 'General/OBC/EWS/Other State Fee', rowElementresult: '₹700' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹400' },
        { rowElement: 'Application Fee for Females (Bihar Domicile)', rowElementresult: '₹400' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online / Offline Mode' },
    ];

    const importantLinkData = [
        { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'https://www.sarkariresult.com/apply-online' },
        { rowElement: 'Download Notification', rowElementresult: 'Click Here', resultLink: '' },
        { rowElement: 'Download Admit Card', rowElementresult: 'Click Here', resultLink: '' },
        { rowElement: 'Download Result', rowElementresult: 'Click Here', resultLink: '' },
        { rowElement: 'BPSSC Official Website', rowElementresult: 'Click Here', resultLink: 'https://www.bpssc.bih.nic.in' },
    ];

    return (
        <Createnewjob
            URL='https://www.bpssc.bih.nic.in'
            mainHead={"Bihar Police BPSSC Sub Inspector SI Recruitment 2024"}
            head={"Bihar Police BPSSC Sub Inspector (SI) Recruitment 2024"}
            ageLimitData={ageLimitData}
            eligibilityData={eligibilityData}
            dateData={dateData}
            importantLinkData={importantLinkData}
            applicationFeeData={applicationFeeData}
            vacancyDetails={vacancyDetails}
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
};

export default BiharPoliceSI;
