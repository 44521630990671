import React from 'react'
import DoubleColumnElement from '../DoubleColumnElement';

const ApplicationFee = ({applicationFeeData}) => {
  return (
    <><div className='p-2 md:p-4 shadow-lg mt-2'>
    <h2 className='font-semibold text-xl sm:text-2xl text-center p-2 bg-gradient-to-br from-sky-900 to-sky-400 text-white'>
      Application Fee
    </h2>
    <ol className='list-none'>
    {applicationFeeData.map((data,index) =>(
      <DoubleColumnElement
      key={index}
      rowElement={data.rowElement}
      rowElementresult={data.rowElementresult}
      />
    ))}
   </ol>
  </div></>
  )
}
export default ApplicationFee;