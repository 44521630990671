export const biharJobLinks = {
    biharPoliceSi: {
        url: '/job/bihar-police-si',
        linkLabel: 'Bihar Police SI Sub-Inspector Recruitment 2024'
    },
    bpscAssistantTeacher: {
        url: '/job/bpsc-assistant-teacher',
        linkLabel: 'BPSC Assistant Teacher Recruitment 2024'
    },
    bsphcl: {
        url: '/job/bihar-state-power-holding-company-limited-bsphcl',
        linkLabel: 'Bihar State Power Holding Company Limited (BSPHCL) Recruitment 2024'
    },
    btscHealth: {
        url: '/job/bihar-technical-service-commission-btsc-health',
        linkLabel: 'Bihar Technical Service Commission (BTSC) Health Recruitment 2024'
    },
    polytechnic: {
        url: '/job/bihar-polytechnic',
        linkLabel: 'Bihar Polytechnic Recruitment 2024'
    },
    BiharDistrictCourtRecruitment: {
        url: '/job/bihar-district-civil-court',
        linkLabel: 'Bihar Civil court recruitment'
    }
};
