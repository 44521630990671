import React from 'react'
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const RrbAlpRecruitment = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: '20 January 2024' },
        { rowElement: 'Application Deadline', rowElementresult: '19 February 2024' },
        { rowElement: 'Correction/Modified Form', rowElementresult: '20-29 February 2024' },
        { rowElement: 'Re-upload Photo/Signature', rowElementresult: '27-31 May 2024' },
        { rowElement: 'Zone Wise Revised Vacancy', rowElementresult: '04 July 2024' },
        { rowElement: 'Change Zone/Preferences', rowElementresult: '29 July 2024 to 07 August 2024' },
        { rowElement: 'Exam Date', rowElementresult: 'As per Schedule' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '18 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '33 Years' },
        { rowElement: 'Age Relaxation', rowElementresult: 'As per RRB ALP Recruitment Advt. CEN 01/2024 Rules' },
    ];

    const vacancyDetails = [
        { rowElement: 'Assistant Loco Pilot', rowElementresult: '18799' },
    ];

    const eligibilityData = [
        { rowElement: 'Educational Qualification', rowElementresult: 'Class 10th with ITI or Diploma/B.E./B.Tech in Mechanical/Electrical/Electronics/Automobile Engineering' },
    ];

    const applicationFeeData = [
        { rowElement: 'General/OBC/EWS Fee', rowElementresult: '₹500' },
        { rowElement: 'EBC/SC/ST/Female Fee', rowElementresult: '₹250' },
        { rowElement: 'Refund after Exam (General/OBC/EWS)', rowElementresult: '₹400' },
        { rowElement: 'Refund after Exam (SC/ST/EBC/Female)', rowElementresult: '₹250' },
    ];

    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://www.rrbapply.gov.in/#/auth/landing' },
        { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://www.rrbapply.gov.in/#/auth/landing' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://www.rrbapply.gov.in/#/auth/landing' },
        { rowElement: 'Re-upload Photo/Signature', rowElementresult: 'Upload Here', resultLink: 'https://www.rrbapply.gov.in/#/auth/landing' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'RRB Ahmedabad WR', ur: 394, sc: 132, st: 67, obc: 244, ews: 100 },
        { postName: 'RRB Ajmer NWR', ur: 314, sc: 113, st: 56, obc: 204, ews: 74 },
        { postName: 'RRB Bangalore SWR', ur: 640, sc: 235, st: 118, obc: 426, ews: 157 },
        { postName: 'RRB Bilaspur SECR', ur: 1610, sc: 595, st: 298, obc: 1072, ews: 398 },
        { postName: 'RRB Secunderabad SCR', ur: 756, sc: 273, st: 149, obc: 491, ews: 194 },
        { postName: 'Total', ur: 8149, sc: 2735, st: 1579, obc: 4538, ews: 1798 },
    ];

    return (
        <>
            <Createnewjob
                URL='https://www.rrbapply.gov.in/#/auth/landing'
                mainHead={"Railway Assistant Loco Pilot (ALP) Recruitment 2024"}
                head={"RRB Assistant Loco Pilot (ALP) Recruitment 2024 Details"}
                dateData={dateData}
                ageLimitData={ageLimitData}
                eligibilityData={eligibilityData}
                applicationFeeData={applicationFeeData}
                importantLinkData={importantLinkData}
                vacancyDetails={vacancyDetails}
                categoryWiseVacancyDetail={categoryWiseVacancyDetail}
            />
        </>
    );
}

export default RrbAlpRecruitment;
