export const defenceJobLinks = {
    afcat: { url: '/job/air-force-common-admission-test-afcat', linkLabel: 'AFCAT Latest Job Information' },
    cds: { url: '/job/combined-defence-services-cds', linkLabel: 'CDS Latest Job Information' },
    indianCoastGuard: { url: '/job/indian-coast-guard-icg', linkLabel: 'Indian Coast Guard Latest Job Information' },
    inet: { url: '/job/indian-navy-entrance-test-inet', linkLabel: 'INET Latest Job Information' },
    indianNavySsr: { url: '/job/indian-navy-ssr', linkLabel: 'Indian Navy SSR Latest Job Information' },
    territorialArmy: { url: '/job/territorial-army-force-ta', linkLabel: 'Territorial Army Latest Job Information' },
    navalAcademy: { url: '/job/naval-academy', linkLabel: 'Naval Academy Latest Job Information' },
    nda: { url: '/job/national-defence-academy-nda', linkLabel: 'NDA Latest Job Information' },

}