import React from 'react';
import ImportantDates from './detail-link-component/ImportantDates';
import AgeLimit from './detail-link-component/AgeLimit';
import Eligibility from './detail-link-component/Eligibility';
import ApplicationFee from './detail-link-component/ApplicationFee';
import Disclaimer from './detail-link-component/Disclaimer';
import VacancyDetail from './detail-link-component/VacancyDetail';
import ImportantLinks from './detail-link-component/ImportantLinks';

const Createnewjob = ({ 
  ageLimitData, 
  eligibilityData, 
  dateData, 
  applicationFeeData, 
  importantLinkData, 
  head, 
  mainHead, 
  vacancyDetails, 
  categoryWiseVacancyDetail 
}) => {
  
  // useEffect(() => {
  //   const loadAds = () => {
  //     try {
  //       (window.adsbygoogle = window.adsbygoogle || []).push({});
  //     } catch (e) {
  //       console.error("AdSense Error: ", e);
  //     }
  //   };
    
  //   loadAds(); // Load ads on component mount
    
  //   // Optionally re-trigger when the window resizes to ensure proper width is available
  //   const handleResize = () => {
  //     loadAds();
  //   };
    
  //   window.addEventListener('resize', handleResize);
    
  //   return () => {
  //     window.removeEventListener('resize', handleResize); // Clean up the listener
  //   };
  // }, []);

  return (
    <>
      <div className='flex flex-col items-center justify-center'>
        {/* Heading */}
        <h1 className='lg:text-2xl text-lg text-center font-bold p-3 w-[87vw] rounded-xl mt-2 shadow-md bg-blue-50 shadow-b text-sky-950'>
          {mainHead}
        </h1>

        {/* Ad Slot
        <ins className="adsbygoogle"
          style={{ display: 'block', width: '100%' }} // Ensure the ad container has width
          data-ad-client="ca-pub-7823800374754559"
          data-ad-slot="8054057983"
          data-ad-format="auto"
          data-full-width-responsive="true">
        </ins> */}

        <div className='lg:w-[86vw] lg:flex '>
          <div className='pt-6 lg:w-[54vw] flex flex-col items-center'>
            <section className='md:p-2 lg:p-4 border border-slate-400 rounded-md md:w-full w-[98vw] mb-6'>
              {/* Important dates */}
              <ImportantDates head={head} dates={dateData} />
              {/* Age limit */}
              <AgeLimit ageLimitData={ageLimitData} />
              {/* Eligibility */}
              <Eligibility eligibilityData={eligibilityData} />
              {/* Vacancy details */}
              <VacancyDetail vacancyDetails={vacancyDetails} categoryWiseVacancyDetail={categoryWiseVacancyDetail} />
              {/* Application fee */}
              <ApplicationFee applicationFeeData={applicationFeeData} />
              {/* Important links */}
              <ImportantLinks importantLinkData={importantLinkData} />
            </section>

            {/* Another Ad Slot */}
            {/* <ins className="adsbygoogle"
              style={{ display: 'block', width: '100%' }} // Ensure width
              data-ad-client="ca-pub-7823800374754559"
              data-ad-slot="7041754447"
              data-ad-format="autorelaxed">
            </ins> */}

            <Disclaimer />
          </div>

          {/* Another Ad Slot
          <ins className="adsbygoogle"
            style={{ display: 'block', width: '70%' }} // Ensure width
            data-ad-client="ca-pub-7823800374754559"
            data-ad-slot="5240192381"
            data-ad-format="auto"
            data-full-width-responsive="true">
          </ins> */}
        </div>
      </div>
    </>
  );
}

export default Createnewjob;
