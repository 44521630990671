import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const CombinedDefenceServices = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: 'As per schedule' },
        { rowElement: 'Application Deadline', rowElementresult: 'As per schedule' },
        { rowElement: 'Admit Card Release Date', rowElementresult: 'As per schedule' },
        { rowElement: 'Exam Date', rowElementresult: 'As per schedule' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '19 Years (Unmarried candidates only)' },
        { rowElement: 'Maximum Age', rowElementresult: '25 Years (Unmarried candidates only)' },
    ];

    const vacancyDetails = [
        { rowElement: 'Indian Military Academy', rowElementresult: '100-200' },
        { rowElement: 'Indian Naval Academy', rowElementresult: '40-50' },
        { rowElement: 'Air Force Academy', rowElementresult: '30-35' },
        { rowElement: 'Officer Training Academy (Men)', rowElementresult: '160-170' },
        { rowElement: 'Officer Training Academy (Women)', rowElementresult: '15-20' },
    ];

    const eligibilityData = [
        { rowElement: 'Educational Qualification', rowElementresult: 'Graduation degree & for some posts (B.Tech/B.E)' },
        { rowElement: 'Experience Required', rowElementresult: 'No Experience' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
        { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
    ];

    const applicationFeeData = [
        { rowElement: 'General/OBC Fee', rowElementresult: '₹200' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹00' },
        { rowElement: 'Application Fee for Females', rowElementresult: '₹00' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
    ];

    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://upsc.gov.in/examinations/Combined%20Defence%20Services%20Examination%20%28I%29%2C%202024' },
        { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://upsc.gov.in/examinations/Combined%20Defence%20Services%20Examination%20%28I%29%2C%202024' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://upsc.gov.in/examinations/Combined%20Defence%20Services%20Examination%20%28I%29%2C%202024' },
        { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://upsc.gov.in/examinations/Combined%20Defence%20Services%20Examination%20%28I%29%2C%202024' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'Combined Defence Services (CDS) Examination', ur: 0, sc: 0, st: 0, obc: 0, ews: 0 },
    ];

    return (
        <>
            <Createnewjob 
                URL='' 
                mainHead={"Combined Defence Services (CDS) Examination 2024 Result"}
                head={"Combined Defence Services (CDS) Examination 2024 Result"}
                ageLimitData={ageLimitData}
                eligibilityData={eligibilityData}
                dateData={dateData}
                importantLinkData={importantLinkData}
                applicationFeeData={applicationFeeData}
                vacancyDetails={vacancyDetails}
                categoryWiseVacancyDetail={categoryWiseVacancyDetail}
            />
        </>
    );
}

export default CombinedDefenceServices;
