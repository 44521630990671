import React from 'react';

const CategoryWiseVacancyDetail = ({ categoryWiseVacancyDetail }) => {
  // Function to check if a value is a valid number
  const isNumber = (value) => typeof value === 'number' && !isNaN(value);

  // Function to calculate the sum or return 'NA'
  const calculateSum = (data) => {
    const { ur, sc, st, obc, ews } = data;
    if ([ur, sc, st, obc, ews].every(isNumber)) {
      return ur + sc + st + obc + ews;
    } else {
      return 'NA';
    }
  };

  return (
    <div className='max-w-[100vw] mx-auto overflow-x-auto'>
      <h2 className='font-semibold text-lg sm:text-xl text-center p-1 bg-gradient-to-r text-pink-700'>
        Category Wise Vacancy Details
      </h2>
      {/* table head */}
      <table className='w-full border-collapse table-auto'>
        <thead>
          <tr>
            <th className='border px-2 py-1 border-slate-400'>POST NAME</th>
            <th className='border px-2 py-1 border-slate-400'>UR</th>
            <th className='border px-2 py-1 border-slate-400'>SC</th>
            <th className='border px-2 py-1 border-slate-400'>ST</th>
            <th className='border px-2 py-1 border-slate-400'>OBC</th>
            <th className='border px-2 py-1 border-slate-400'>EWS</th>
            <th className='border px-2 py-1 border-slate-400'>TOTAL</th>
          </tr>
        </thead>
        {/* table body */}
        <tbody>
          {categoryWiseVacancyDetail.map((data, index) => (
            <tr key={index}>
              <td className='border border-slate-400 px-1 text-center py-1'>{data.postName}</td>
              <td className='border border-slate-400 px-1 text-center py-1'>{data.ur}</td>
              <td className='border border-slate-400 px-1 text-center py-1'>{data.sc}</td>
              <td className='border border-slate-400 px-1 text-center py-1'>{data.st}</td>
              <td className='border border-slate-400 px-1 text-center py-1'>{data.obc}</td>
              <td className='border border-slate-400 px-1 text-center py-1'>{data.ews}</td>
              <td className='border border-slate-400 px-1 text-center py-1 text-pink-900 font-bold'>
                {calculateSum(data)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryWiseVacancyDetail;
