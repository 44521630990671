import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const UppscAgricultureServices = () => {
    const dateData = [
        { rowElement: 'Application Begin', rowElementresult: '10/04/2024' },
        { rowElement: 'Last Date for Apply Online', rowElementresult: '10/05/2024' },
        { rowElement: 'Last Date Pay Exam Fee', rowElementresult: '10/05/2024' },
        { rowElement: 'Correction Last Date', rowElementresult: '16/05/2024' },
        { rowElement: 'Pre Exam Date', rowElementresult: '18/08/2024' },
        { rowElement: 'Admit Card Available', rowElementresult: '09/08/2024' },
        { rowElement: 'Answer Key Available', rowElementresult: '22/08/2024' },
        { rowElement: 'Pre Result Available', rowElementresult: '19/09/2024' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '21 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '40 Years' },
        { rowElement: 'Age Relaxation', rowElementresult: 'Extra as per rules' },
    ];

    const vacancyDetails = [
        { rowElement: 'Total Vacancies', rowElementresult: '268 Posts' },
        { rowElement: 'District Horticulture Officer Group-2', rowElementresult: 'Various' },
        { rowElement: 'Principal Govt. Food Science Training Center', rowElementresult: 'Various' },
        { rowElement: 'Senior Technical Assistant Group-A', rowElementresult: 'Various (Agronomy, Botany, etc.)' },
    ];

    const eligibilityData = [
        { rowElement: 'Educational Qualification', rowElementresult: 'Bachelor’s Degree in Relevant Field' },
        { rowElement: 'Specific Qualifications', rowElementresult: 'As per post requirements' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
    ];

    const applicationFeeData = [
        { rowElement: 'General / OBC Fee', rowElementresult: '₹125' },
        { rowElement: 'SC / ST Fee', rowElementresult: '₹65' },
        { rowElement: 'PH Candidates Fee', rowElementresult: '₹25' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'SBI Mops, Debit Card, Credit Card, Net Banking or SBI E Challan' },
    ];

    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://uppsc.up.nic.in/OuterPages/View_Enclosure.aspx?ID=803&flag=E&FID=836' },
        { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://uppsc.up.nic.in/' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://uppsc.up.nic.in/' },
        { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://drive.google.com/file/d/1XjdHECO4yN1zXzIUN1m6rnvU7hevcImq/view?usp=sharing' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'District Horticulture Officer Group-2', ur: 100, sc: 50, st: 20, obc: 30, ews: 20 },
        { postName: 'Principal Govt. Food Science Training Center', ur: 60, sc: 30, st: 10, obc: 20, ews: 15 },
        { postName: 'Senior Technical Assistant Group-A (Agronomy)', ur: 50, sc: 25, st: 10, obc: 15, ews: 10 },
        // Add more posts as needed
    ];

    return (
        <Createnewjob  
            mainHead={"UPPSC Combined State Agriculture Services Examination 2024"} 
            head={"UPPSC Agriculture Services Exam 2024 Details"} 
            ageLimitData={ageLimitData} 
            eligibilityData={eligibilityData} 
            dateData={dateData} 
            importantLinkData={importantLinkData} 
            applicationFeeData={applicationFeeData} 
            vacancyDetails={vacancyDetails} 
            categoryWiseVacancyDetail={categoryWiseVacancyDetail} 
        />
    );
};

export default UppscAgricultureServices;
