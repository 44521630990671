import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const IndianNavyEntranceTest = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: 'As per schedule' },
        { rowElement: 'Application Deadline', rowElementresult: 'As per schedule' },
        { rowElement: 'Admit Card Release Date', rowElementresult: 'As per schedule' },
        { rowElement: 'Exam Date', rowElementresult: 'As per schedule' },
    ];

    // Age Limit Data
    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '18 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '25 Years for Chargeman & Tradesman, 27 Years for Senior Draughtsman' },
    ];

    // Vacancy Details
    const vacancyDetails = [
        { rowElement: 'Indian Navy Entrance Test (INET)', rowElementresult: 'NA' },
    ];

    // Eligibility Criteria
    const eligibilityData = [
        { rowElement: 'Chargeman', rowElementresult: 'Bachelor’s Degree in Science with Physics OR Chemistry OR Mathematics as a Subject OR Diploma in Chemical / Electrical / Electronics / Mechanical / Computer Engineering' },
        { rowElement: 'Senior Draughtsman', rowElementresult: 'Class 10 (High School) Exam Passed from any recognized board in India. 2-Year Diploma / Certificate in Draughtsmanship. Engineering 3-Year Diploma in Related Trade / Post' },
        { rowElement: 'Tradesman', rowElementresult: 'Class 10th Matric with ITI Certificate in Related Trade.' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
        { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
    ];

    // Application Fee
    const applicationFeeData = [
        { rowElement: 'General/OBC Fee', rowElementresult: '₹295' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹00' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
    ];

    // Important Links
    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://incet.cbt-exam.in/login/user' },
        { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://incet.cbt-exam.in/login/user' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://incet.cbt-exam.in/login/user' },
        { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://incet.cbt-exam.in/incetcycle1/images/dcmpr_documents/INCET_01_2023_Provisional_Select_List.pdf' },
    ];

    // Category-wise Vacancy Detail
    const categoryWiseVacancyDetail = [
        { postName: 'Indian Navy Entrance Test (INET)', ur:'NA' , sc:'NA' , st:'NA', obc:'NA', ews:'NA' },
    ];

    return (
        <>
            <Createnewjob 
                URL='https://incet.cbt-exam.in/login/user'
                mainHead={"Indian Navy Entrance Test (INET) Examination 2024"}
                head={"Indian Navy Entrance Test (INET) Examination 2024"}
                ageLimitData={ageLimitData}
                eligibilityData={eligibilityData}
                dateData={dateData}
                importantLinkData={importantLinkData}
                applicationFeeData={applicationFeeData}
                vacancyDetails={vacancyDetails}
                categoryWiseVacancyDetail={categoryWiseVacancyDetail}
            />
        </>
    );
}

export default IndianNavyEntranceTest;
