import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const UpPscRecruitment = () => {
    const dateData = [
        { rowElement: 'Application Begin', rowElementresult: '30 May 2018' },
        { rowElement: 'Last Date for Apply Online', rowElementresult: '25 June 2018' },
        { rowElement: 'Last Date Pay Exam Fee', rowElementresult: '27 June 2018' },
        { rowElement: 'Last Date Complete Form', rowElementresult: '29 June 2018' },
        { rowElement: 'Exam Date', rowElementresult: '22-23 December 2018' },
        { rowElement: 'Re Exam Date', rowElementresult: '26-27 June 2023' },
        { rowElement: 'Admit Card Available', rowElementresult: 'Before Exam' },
        { rowElement: 'Answer Key Available', rowElementresult: '13 July 2023' },
        { rowElement: 'Revised Answer Key Available', rowElementresult: '06 October 2023' },
        { rowElement: 'Re Exam Result Available', rowElementresult: '21 February 2024' },
        { rowElement: 'Document Verification DV Start', rowElementresult: '29 April 2024' },
        { rowElement: 'Supplementary Result Available', rowElementresult: '09 August 2024' },
        { rowElement: 'Document Verification Letter Available', rowElementresult: '13 August 2024' },
        { rowElement: 'Final Result Available', rowElementresult: '12 September 2024' },
    ];

    const ageLimitData = [
        { rowElement: 'Age Limit', rowElementresult: '18-40 Years as on 01 July 2018' },
    ];

    const eligibilityData = [
        { rowElement: 'Gram Panchayat Adhikari', rowElementresult: '10+2 Intermediate Exam with CCC Certificate from NIELIT' },
        { rowElement: 'Gram Vikas Adhikari', rowElementresult: '10+2 Intermediate Exam with CCC Certificate from NIELIT' },
        { rowElement: 'Samaj Kalyan Prayvekshak', rowElementresult: '10+2 Intermediate Exam with CCC Certificate from NIELIT' },
    ];

    const applicationFeeData = [
        { rowElement: 'General / OBC Fee', rowElementresult: '₹185' },
        { rowElement: 'SC / ST Fee', rowElementresult: '₹95' },
        { rowElement: 'PH (Divyang) Fee', rowElementresult: '₹25' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'SBI I Collect Fee Mode Only' },
    ];

    const vacancyDetails = [
        { rowElement: 'Total Vacancies', rowElementresult: '1953' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'Gram Panchayat Adhikari', ur: 841, obc: 370, sc: 296, st: 20, total: 1527 },
        { postName: 'Gram Vikas Adhikari', ur: 182, obc: 97, sc: 76, st: 7, total: 362 },
        { postName: 'Samaj Kalyan Prayvekshak', ur: 33, obc: 17, sc: 14, st: 0, total: 64 },
    ];

    const importantLinkData = [
        { rowElement: 'Download Final Result', rowElementresult: 'Click Here', resultLink: 'https://drive.google.com/file/d/1q71UnunskcocLwzkL3SvTnq6I0DNiySb/view?usp=sharing' },
        { rowElement: 'Download Document Verification Letter', rowElementresult: 'Click Here', resultLink: 'https://upsssc.gov.in/Online_App/Download_InterviewLetter_Exam.aspx?ID=DV' },
        { rowElement: 'Download Document Verification Notice', rowElementresult: 'Click Here', resultLink: 'http://example.com/dv-notice' },
        { rowElement: 'Download Supplementary Result', rowElementresult: 'Click Here', resultLink: 'https://drive.google.com/file/d/1nxIoRz52930KGKwnNV1KMCaX7YN_zsE-/view?usp=sharing' },
        { rowElement: 'Download DV Schedule', rowElementresult: 'Click Here', resultLink: 'http://example.com/dv-schedule' },
        { rowElement: 'Download Result', rowElementresult: 'Click Here', resultLink: 'https://upsssc.gov.in/ResultsDire.aspx' },
        { rowElement: 'Download Result Notice / Cutoff', rowElementresult: 'Click Here', resultLink: 'http://example.com/result-notice' },
        { rowElement: 'Download Admit Card Notice', rowElementresult: 'Click Here', resultLink: 'http://example.com/admit-card-notice' },
        { rowElement: 'Download Re Exam Notice', rowElementresult: 'Click Here', resultLink: 'http://example.com/re-exam-notice'},
        { rowElement: 'Download Document Verification Letter', rowElementresult: 'Click Here', resultLink: 'http://example.com/dv-letter' },
        { rowElement: 'Download Result (Selected for DV Test)', rowElementresult: 'Click Here', resultLink: 'http://example.com/result-dv-test' },
        { rowElement: 'Download Result', rowElementresult: 'Click Here', resultLink: 'https://upsssc.gov.in/ResultsDire.aspx' },
        { rowElement: 'Official Website', rowElementresult: 'Click Here', resultLink: 'https://upsssc.gov.in/Default.aspx' },
    ];

    return (
        <Createnewjob 
            URL='http://example.com' // Replace with actual URL
            mainHead={"UPSSSC Gram Panchyat Adhikari, Gram Vikas Adhikari, and Samaj Kalyan Prayveksh Recruitment 2018"}
            head={"UPSSSC Gram Panchyat Adhikari, Gram Vikas Adhikari, and Samaj Kalyan Prayveksh Recruitment 2018 Details"}
            ageLimitData={ageLimitData}
            eligibilityData={eligibilityData}
            dateData={dateData}
            importantLinkData={importantLinkData}
            applicationFeeData={applicationFeeData}
            vacancyDetails={vacancyDetails}
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
};

export default UpPscRecruitment;
