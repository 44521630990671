import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const RrbGroupDResult = () => {
  const dateData = [
    { rowElement: 'Stage I Result Released', rowElementresult: '04 March 2019' },
    { rowElement: 'Stage II Exam Date', rowElementresult: 'Notified Soon' },
    { rowElement: 'Stage II Admit Card Release Date', rowElementresult: 'Notified Soon' },
  ];

  const vacancyDetails = [
    { rowElement: 'RRB Bilaspur', rowElementresult: 'NA', },
    { rowElement: 'RRB Mumbai', rowElementresult: 'NA',  },
    { rowElement: 'RRB Ahmedabad', rowElementresult: 'NA', },
    { rowElement: 'RRB Allahabad', rowElementresult: 'NA',  },
    { rowElement: 'RRB Chennai', rowElementresult: 'NA', },
    { rowElement: 'RRB Patna', rowElementresult: 'NA', },
    { rowElement: 'RRB Bhopal', rowElementresult: 'NA',  },
    { rowElement: 'RRB Ranchi', rowElementresult: 'NA', },
    { rowElement: 'RRB Chandigarh', rowElementresult: 'NA', },
    { rowElement: 'RRB Guwahati', rowElementresult: 'NA',  },
    { rowElement: 'RRB Gorakhpur', rowElementresult: 'NA', },
    { rowElement: 'RRB Jammu-Srinagar', rowElementresult: 'NA',  },
    { rowElement: 'RRB Kolkata', rowElementresult: 'NA', },
    { rowElement: 'RRB Ajmer', rowElementresult: 'NA', },
    { rowElement: 'RRB Muzaffarpur', rowElementresult: 'Soon',  },
    { rowElement: 'RRB Malda', rowElementresult: 'NA', },
    { rowElement: 'RRB Thiruvananthapuram', rowElementresult: 'NA', },
    { rowElement: 'RRB Secunderabad', rowElementresult: 'NA', },
    { rowElement: 'RRB Bangalore', rowElementresult: 'NA',},
    { rowElement: 'RRB Siliguri', rowElementresult: 'NA',  },
    { rowElement: 'RRB Bhubaneswar', rowElementresult: 'NA', },
  ];

  const categoryWiseVacancyDetail = [
    { postName: 'RRB GROUP D', ur: 100, sc: 200, st: 500, obc: 150, ews: 110 }
  ];

  const ageLimitData = [
    { rowElement: 'Minimum Age', rowElementresult: '18 Years' },
    { rowElement: 'Maximum Age for General Category', rowElementresult: '30 Years' },
    { rowElement: 'Maximum Age for OBC Category', rowElementresult: '33 Years' },
    { rowElement: 'Maximum Age for SC/ST Category', rowElementresult: '35 Years' },
  ];

  const eligibilityData = [
    { rowElement: 'Educational Qualification', rowElementresult: 'Bachelor’s Degree in Relevant Field' },
    { rowElement: 'Experience Required', rowElementresult: 'Minimum 2 Years' },
    { rowElement: 'Nationality', rowElementresult: 'Indian' },
    { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
  ];

  const applicationFeeData = [
    { rowElement: 'General/OBC Fee', rowElementresult: '₹500' },
    { rowElement: 'SC/ST Fee', rowElementresult: '₹250' },
    { rowElement: 'Application Fee for Females', rowElementresult: '₹250' },
    { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
  ];

  const importantLinkData = [
    { rowElement: 'Login to View Score Card', rowElementresult: 'NA', resultLink: 'https://example.com/login' },
    { rowElement: 'How to Check Result', rowElementresult: 'NA', resultLink: 'https://example.com/how-to-check-result' },
    { rowElement: 'RRB ALP 02/2018 Vacancy Details', rowElementresult: 'NA', resultLink: 'https://example.com/vacancy-details' },
    { rowElement: 'Official Website', rowElementresult: 'NA', resultLink: 'https://example.com/official-website' },
  ];

  return (
    <Createnewjob
      URL="https://example.com"
      mainHead="Railway Recruitment Board RRB Group D Exam Result 2018"
      head="RRB Group D Exam Advt No 02/2018 Result"
      dateData={dateData}
      importantLinkData={importantLinkData}
      categoryWiseVacancyDetail={categoryWiseVacancyDetail}
      applicationFeeData={applicationFeeData}
      eligibilityData={eligibilityData}
      ageLimitData={ageLimitData}
      vacancyDetails={vacancyDetails}
    />
  );
};

export default RrbGroupDResult;
