export const upJobLinks = {
    healthTraining: { url: '/job/up-gnm-anm-health-training', linkLabel: 'UP GNM/ANM Health Training 2024' },
    upParamedical: { url: '/job/up-paramedical', linkLabel: 'UP Paramedical Recruitment 2024' },
    upPoliceSIASi: {
        url: '/job/up-sias-i-senior-industrial-assistant-sub-inspector',
        linkLabel: 'UP SIAS-I (Senior Industrial Assistant Sub-Inspector) Recruitment 2024'
    },
    upTet: {
        url: '/job/up-tet-teacher-eligibility-test',
        linkLabel: 'UP TET (Teacher Eligibility Test) Exam 2024'
    },
    upSssc: {
        url: '/job/up-sssc',
        linkLabel: 'UpSSSC 2024 latest updates'
    },
    upAgriculture: {
        url: '/job/uppsc-agriculture-services',
        linkLabel: 'UP Agriculture service pre result'
    }
};
