// AnswerKeyLinks.js
const AnswerKeyLinks = [
  { url: '/job/ssc-cgl', linkLabel: 'SSC-CGL 2024 Answer Key Information' },
  { url: '/job/ssc-chsl', linkLabel: 'SSC-CHSL 2024 Answer Key Information' },
  { url: '/job/ssc-gd', linkLabel: 'SSC-GD 2024 Answer Key Information' },
  { url: '/job/ssc-stenographer', linkLabel: 'SSC-Stenographer 2024 Answer Key Information' },
  { url: '/job/ssc-mts', linkLabel: 'SSC-MTS 2024 Answer Key Information' },
  { url: '/job/air-force-common-admission-test-afcat', linkLabel: 'AFCAT Answer Key Information' },
  { url: '/job/combined-defence-services-cds', linkLabel: 'CDS Answer Key Information' },
  { url: '/job/indian-coast-guard-icg', linkLabel: 'Indian Coast Guard Answer Key Information' },
  { url: '/job/indian-navy-entrance-test-inet', linkLabel: 'INET Answer Key Information' },
  { url: '/job/indian-navy-ssr', linkLabel: 'Indian Navy SSR Answer Key Information' },
  { url: '/job/territorial-army-force-ta', linkLabel: 'Territorial Army Answer Key Information' },
  { url: '/job/naval-academy', linkLabel: 'Naval Academy Answer Key Information' },
  { url: '/job/national-defence-academy-nda', linkLabel: 'NDA Answer Key Information' },
  { url: '/job/railways-rpf-exam-2024', linkLabel: 'RRB Assistant Loco Pilot Recruitment 2024 Answer Key Information' },
  { url: '/job/rrb-junior-engineer-je', linkLabel: 'RRB Junior Engineer Exam 2024 Answer Key Information' },
  { url: '/job/rrb-railway-protection-force-rpf', linkLabel: 'RRB Railway Protection Force Exam 2024 Answer Key Information' },
  { url: '/job/rrb-ntpc-non-technical-popular-categories', linkLabel: 'RRB Non-Technical Popular Categories Exam 2024 Answer Key Information' },
  { url: '/job/rrb-group-d', linkLabel: 'RRB Group D Recruitment 2024 Answer Key Information' },
  { url: '/job/rrb-paramedical', linkLabel: 'RRB Paramedical Recruitment 2024 Answer Key Information' },
  { url: '/job/bihar-police-si', linkLabel: 'Bihar Police SI Sub-Inspector Recruitment 2024 Answer Key Information' },
  { url: '/job/bpsc-assistant-teacher', linkLabel: 'BPSC Assistant Teacher Recruitment 2024 Answer Key Information' },
  { url: '/job/bihar-state-power-holding-company-limited-bsphcl', linkLabel: 'Bihar State Power Holding Company Limited (BSPHCL) Recruitment 2024 Answer Key Information' },
  { url: '/job/bihar-technical-service-commission-btsc-health', linkLabel: 'Bihar Technical Service Commission (BTSC) Health Recruitment 2024 Answer Key Information' },
  { url: '/job/bihar-polytechnic', linkLabel: 'Bihar Polytechnic Recruitment 2024 Answer Key Information' },
  { url: '/job/up-gnm-anm-health-training', linkLabel: 'UP GNM/ANM Health Training 2024 Answer Key Information' },
  { url: '/job/up-paramedical', linkLabel: 'UP Paramedical Recruitment 2024 Answer Key Information' },
  { url: '/job/up-sias-i-senior-industrial-assistant-sub-inspector', linkLabel: 'UP SIAS-I (Senior Industrial Assistant Sub-Inspector) Recruitment 2024 Answer Key Information' },
  { url: '/job/up-tet-teacher-eligibility-test', linkLabel: 'UP TET (Teacher Eligibility Test) Exam 2024 Answer Key Information' },
  { url: '/job/upsc', linkLabel: 'UPSC 2024 Answer Key Information' },
  { url: '/job/pnb-apprentice', linkLabel: 'PNB Apprentice Exam 2024 Answer Key Information' }
];

export default AnswerKeyLinks;
