import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const PnbApprentice = () => {
    const dateData = [
        { rowElement: 'Application Begin', rowElementresult: '30 June 2024' },
        { rowElement: 'Last Date for Apply Online', rowElementresult: '14 July 2024' },
        { rowElement: 'Exam Date', rowElementresult: '28 July 2024' },
        { rowElement: 'Result Available', rowElementresult: '11 September 2024' },
    ];

    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '20 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '28 Years' },
        { rowElement: 'Age Relaxation', rowElementresult: 'As per PNB Apprentices Rules' },
    ];

    const vacancyDetails = [
        { rowElement: 'Total Vacancy', rowElementresult: '2700 Posts' },
    ];

    const eligibilityData = [
        { rowElement: 'Educational Qualification', rowElementresult: 'Bachelor’s Degree in Any Stream from a Recognized University in India' },
    ];

    const applicationFeeData = [
        { rowElement: 'General/OBC/EWS Fee', rowElementresult: '₹944' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹708' },
        { rowElement: 'All Category Female', rowElementresult: '₹708' },
        { rowElement: 'PH (Divyang)', rowElementresult: '₹472' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Debit Card/Credit Card/Net Banking' },
    ];

    const importantLinkData = [
        { rowElement: 'Download Result', rowElementresult: 'Click Here', resultLink: 'https://drive.google.com/file/d/1Xn2m78kV8JtzeNktblmkjSXRhvOwZnjJ/view?usp=sharing' },
        { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'https://bfsissc.com/apprentice_form.php' },
        { rowElement: 'Download Notification', rowElementresult: 'Click Here', resultLink: 'https://drive.google.com/file/d/16JVkjRygeZbjLgyKbWdvyGV5UL1ScuMr/view?usp=sharing' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'PNB Apprentices', ur: 1183, obc: 614, ews: 255, sc: 481, st: 167, total: 2700 },
    ];

    return (
        <Createnewjob 
           
            mainHead={"Punjab National Bank (PNB) Apprentices Recruitment 2024"}
            head={"PNB Apprentices Recruitment 2024 Notification"}
            ageLimitData={ageLimitData} 
            eligibilityData={eligibilityData} 
            dateData={dateData}  
            importantLinkData={importantLinkData} 
            applicationFeeData={applicationFeeData} 
            vacancyDetails={vacancyDetails} 
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
}
export default PnbApprentice;
